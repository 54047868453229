import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import { Container, Grid, makeStyles, ThemeProvider } from "@material-ui/core";
import ServicesGrid from "./ServicesGrid";
import useFetch from "./../../Helpers/customHooks/useFetch";
import smoothScrollTop from "../../Helpers/smoothScrollTop";
import theme from "../../theme";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: 30,
  },
}));

const ServicesPage = ({ width, services }) => {
  const classes = useStyles();
  const pageContent = useFetch("Services");

  useEffect(() => {
    smoothScrollTop();
  }, []);

  return (
    <>
      <Helmet>
        <title>MzTech - Services</title>
        <meta
          name="description"
          content="We support IT service industries across North America. Our marketplace includes thousands of qualified service providers with a range of skillsets ready to help you get work done."
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <Grid container>
          <Header />
          <Container className={classes.wrapper} maxWidth="md">
            {pageContent?.Services && (
              <ServicesGrid services={pageContent.Services} />
            )}
          </Container>
        </Grid>
      </ThemeProvider>
    </>
  );
};

export default ServicesPage;
