import { CustomSlider } from "../../../Components";
import sliderSettings from "./sliderSetting";
import CareerCard from "../CareerCard";

const LogosSlider = ({ rtl, content }) => {
  const settings = sliderSettings(rtl);

  return (
    <CustomSlider moreSetting={settings}>
      {content &&
        content.length > 0 &&
        content.map(({ imageUrl, title, summary, jobId }) => (
          <CareerCard
            teamCard
            mx={2}
            imageUrl={imageUrl}
            title={title}
            summary={summary}
            link={`/career/${jobId}`}
          />
        ))}
    </CustomSlider>
  );
};

export default LogosSlider;
