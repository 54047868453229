import {Accordion,AccordionSummary,AccordionDetails,makeStyles} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Text} from '../../Components'

const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: theme.palette.spacing*2,
        padding: theme.palette.spacing,
        borderRadius: 8,
        boxShadow: '0 0 10px #e6e6e6',
    },
}));

const FAQ = (props) => {
    const classes = useStyles(props);
    const {question, answer, id} = props
    return (
        <Accordion className={classes.container} key={id}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Text className={classes.fontBold}>{question}</Text>
            </AccordionSummary>
            <AccordionDetails>
                <Text>{answer}</Text>
            </AccordionDetails>
        </Accordion>
    )
}
export default FAQ