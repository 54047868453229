import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
    navbar:{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        listStyle: 'none',
        padding: theme.palette.spacing*3,
        color: theme.palette.common.textColor
    },
    isClicked: {
        paddingTop: theme.palette.spacing,
        paddingBottom: theme.palette.spacing,
        paddingLeft: theme.palette.spacing * 2,
        paddingRight: theme.palette.spacing * 2,
        borderRadius: 6,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white
    },
    navLink: {
        paddingTop: theme.palette.spacing,
        paddingBottom: theme.palette.spacing,
        paddingLeft: theme.palette.spacing * 2,
        paddingRight: theme.palette.spacing * 2,
        borderRadius: 6,
        [theme.breakpoints.down("md")]: {
            fontSize: theme.fonts.font14,
            paddingTop: theme.palette.spacing * 0.5,
            paddingBottom: theme.palette.spacing * 0.5,
            paddingLeft: theme.palette.spacing,
            paddingRight: theme.palette.spacing,
          },
        '&:hover':{
            cursor: 'pointer',
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white
        }
    }
    
}))
export default useStyles