import React from "react";
import { Grid } from "@material-ui/core";
import { motion } from "framer-motion";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Text } from "..";
import style from "./style";

const IconHeaderText = ({ contents }) => {
  const classes = style();

  const animationItem = {
    hidden: { x: 60, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
    },
  };

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.3,
      },
    },
  };

  return (
    <motion.div variants={container} initial="hidden" animate="visible">
      {contents.map(({ header, text }, element) => (
        <motion.div
          variants={animationItem}
          transition={{ duration: 0.5 }}
          key={element}
        >
          <Grid item xs={12}>
            <div className={classes.header}>
              <CheckCircleIcon className={classes.icon} />
              <Text className={classes.headerText}>{header}</Text>
            </div>
            <Text variant="body1" key={element} className={classes.text}>
              {text}
            </Text>
          </Grid>
        </motion.div>
      ))}
    </motion.div>
  );
};

export default IconHeaderText;
