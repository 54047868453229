import React from 'react'
import {Box, Grid} from '@material-ui/core'
import {useStyle} from './Utility'

const ProjectImage = ({content, title}) => {
    const classes = useStyle()
    return (
        <Box py={3}>
            <Grid container justify='center' alignItems='center'>
                <Grid item xs>
                    <img src={content?.imageUrl} alt={title} className={classes.projectImageSize} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default ProjectImage
