import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container } from "@material-ui/core";
import Header from "./Header";
import Card from "./Card";
import CountSection from "./CountSection";
import ClientStory from "./ClientStory";
import useFetch from "./../../Helpers/customHooks/useFetch";
import TechnologyUsed from "../serviceDetailPage/TechnologyUsed";
import { contents } from "./Utility";
import { LogosSlider, Text } from "../../Components";
import companyLogos from "../../Components/LogosSlider/logosData";
import PayoneersMessage from "../HomePage/payoneersMessage/PayoneersMessage";
import smoothScrollTop from "../../Helpers/smoothScrollTop";
import { Box } from "@material-ui/core";

const AboutUs = () => {
  useFetch("About Us");
  useEffect(() => {
    smoothScrollTop();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <title>MzTech - About Us</title>
        <meta
          name="description"
          content="MzTech is a team of accomplished engineers and tech professionals who are committed to utilizing state-of-the-art resources to help clients acquire their digital aspirations."
        />
      </Helmet>
      <Header />
      <Card />
      <CountSection />
      <ClientStory />
      <PayoneersMessage />

      <Box py={4} style={{ backgroundColor: "#fff" }}>
        <Text align="center" variant="h2">
          Our Clients
        </Text>
      </Box>
      <Box style={{ backgroundColor: "#fff" }}>
        <Container maxWidth="md">
          <LogosSlider content={companyLogos[0]} />
          <LogosSlider content={companyLogos[1]} rtl />
        </Container>
      </Box>

      <TechnologyUsed content={contents[0]} bgColor="#fff" />
    </>
  );
};

export default AboutUs;
