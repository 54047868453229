/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  pageContentSelector,
  storingPageContentsToRedux,
} from "./../../Redux/Slices/pageContentSlice";

const useFetch = (pageTitle) => {
  const dispatch = useDispatch();
  const { pageContent } = useSelector(pageContentSelector);
  const storePageContent = async () => {
    dispatch(await storingPageContentsToRedux());
  };
  useEffect(() => {
    if (!pageContent) {
      storePageContent();
    }
  }, []);
  return pageContent;
};
export default useFetch;
