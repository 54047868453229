export const generateId = (path) => {
    let id = ''
    const words = path.split('-')
    words.forEach(word => id +=  word[0].toUpperCase() + word.slice(1))
    return id
}
export const generateName = (path) => {
    let id = ''
    const words = path.split('-')
    words.forEach(word => id +=  word[0].toUpperCase() + word.slice(1) + ' ')
    id = id.slice(0, id.length-1)
    return id
}