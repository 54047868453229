import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  Drawer,
  ListItemText,
  ListItemIcon,
  IconButton,
  Divider,
} from "@material-ui/core";
import { ReactSVG } from "react-svg";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Links } from "../../../Components";
import useStyle from "./indexCss";
import { useState } from "react";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import WorkIcon from "@material-ui/icons/Work";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import SettingsIcon from "@material-ui/icons/Settings";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import Logo from "../../../images/logoFull.svg";
import LogoLight from "../../../images/logoLight.svg";

const Header = (props) => {
  const [toggleDrawer, setToggleDrawer] = useState(false);

  const style = useStyle(props);
  const links = [
    {
      name: "Home",
      to: "/",
      icon: <HomeIcon fontSize="small" />,
    },
    {
      name: "About Us",
      to: "/about",
      icon: <InfoIcon fontSize="small" />,
    },
    {
      name: "Services",
      to: "/services",
      icon: <SettingsIcon fontSize="small" />,
    },
    {
      name: "Our Work",
      to: "/portfolio",
      icon: <WorkIcon fontSize="small" />,
    },
    {
      name: "Career",
      to: "/career",
      icon: <LockOpenIcon fontSize="small" />,
    },
    {
      name: "Contact Us",
      to: "/contact",
      icon: <ContactSupportIcon fontSize="small" />,
    },
  ];

  const list = (
    <Box className={clsx(style.list)} role="presentation">
      <IconButton>
        <ChevronRightIcon />
      </IconButton>
      <Divider />
      <List>
        {links.map((link) => {
          return (
            <Link key={link.name} to={link.to} className={style.linkColor}>
              <ListItem>
                <ListItemIcon>{link?.icon}</ListItemIcon>
                <ListItemText primary={link.name}></ListItemText>
              </ListItem>
            </Link>
          );
        })}
      </List>
    </Box>
  );

  return (
    <Box className={style.header_wrapper}>
      <Container>
        <Grid container spacing={1} justify="space-between" alignItems="center">
          <Grid item>
            <Link to="/">
              <ReactSVG
                className={style.logo}
                src={props.scroll ? LogoLight : Logo}
                alt="MzTech"
              />
            </Link>
          </Grid>
          <Grid item>
            <Box display={{ xs: "none", md: "block" }}>
              <ul className={style.navbar}>
                {links.map((link) => {
                  return (
                    <li key={link.name}>
                      <Links link={link.to} name={link.name} />
                    </li>
                  );
                })}
              </ul>
            </Box>
            <Box display={{ xs: "block", md: "none" }}>
              <IconButton>
                <MenuIcon onClick={() => setToggleDrawer(true)} className={style.hambergurMenu} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Drawer
        anchor="right"
        variant="temporary"
        open={toggleDrawer}
        onClick={() => setToggleDrawer(false)}
      >
        {list}
      </Drawer>
    </Box>
  );
};

export default Header;
