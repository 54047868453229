export const contactInfo = {
  address: "‪8171 Harrison St., New York, NY",
  number: "‪(929) 383-0818",
  email: "info@mztech.us",
};

export const socialInfo = [
  {
    label: "Facebook",
    link: "https://www.facebook.com/mztechcorp",
    color: "#1977F2",
  },
  {
    label: "LinkedIn",
    link: "https://www.linkedin.com/company/mztech-corp",
    color: "#0374B3",
  },
  {
    label: "Twitter",
    link: "https://twitter.com/MztechC",
    color: "#2962FF",
  },
];

export const phoneTransform = () => {
  // +1 (347) 306-5628‬ -> 3473065628‬
  let phoneClean = contactInfo.number
    .replace("+1", "")
    .replace("(", "")
    .replace(") ", "")
    .replace("-", "")
    .replace(" ", "");
  return phoneClean;
};
