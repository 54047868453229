/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  homePageContentSelector,
  storingPageContentsToRedux,
} from "./../../Redux/Slices/homePageContentSlice";

const useFetchHome = (pageTitle) => {
  const dispatch = useDispatch();
  const { homePageContent } = useSelector(homePageContentSelector);
  const storePageContent = async () => {
    dispatch(await storingPageContentsToRedux());
  };
  useEffect(() => {
    if (!homePageContent) {
      storePageContent();
    }
  }, []);
  return homePageContent;
};
export default useFetchHome;
