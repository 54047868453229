import React, { useEffect, useState } from "react";
import { Grid, Button } from "@material-ui/core";
import classNames from "classnames";
import AOS from "aos";
import emailjs from "emailjs-com";
import theme from "./../../../theme";
import {
  lengthChecker,
  nameValidator,
  emailValidator,
  phoneNumberValidator,
  messageValidator,
} from "./../../../Helpers/Validators";
import { Input, Text } from "../..";
import { firestore } from "./../../../Helpers/Firebase";
import style from "./style";

const InputHandler = (props) => {
  const [form, setForm] = useState({
    value: {
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
    },
    errors: {
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
    },
    isDirty: {
      name: false,
      email: false,
      phone: false,
      company: false,
      message: false,
    },
  });
  const [emailSendError, setEmailSendError] = useState();
  const [emailSendSeccess, setEmailSendSeccess] = useState();
  const classes = style(props);

  const { size, home } = props;

  useEffect(() => {
    AOS.init({
      easing: "linear",
    });
  }, []);
  useEffect(() => {
    setForm({
      ...form,
      errors: {
        name:
          form.isDirty.name &&
          (lengthChecker(
            form.value.name,
            3,
            "Name should be at least 3 characters",
            true
          ) ||
            lengthChecker(form.value.name, 25, "Name is too long", false) ||
            nameValidator(
              form.value.name,
              "Name should not contain digits/symbols"
            )),
        email:
          form.isDirty.email &&
          emailValidator(form.value.email, "Please provide a valid email"),
        phone:
          form.isDirty.phone &&
          phoneNumberValidator(
            form.value.phone,
            "Please provide a valid phone number"
          ),
        company:
          form.isDirty.company &&
          nameValidator(
            form.value.company,
            "Company name should not contain digits/symbols"
          ),
        message:
          form.isDirty.message &&
          messageValidator(
            form.value.message,
            15,
            "Message should be at least 15 characters"
          ),
      },
    });
    // eslint-disable-next-line
  }, [form.value]);
  const sendEmail = async (e) => {
    e.preventDefault();
    if (
      !form.errors.name &&
      !form.errors.email &&
      !form.errors.phone &&
      !form.errors.company &&
      !form.errors.message &&
      form.value.name.length &&
      form.value.email.length &&
      form.value.phone.length &&
      form.value.company.length &&
      form.value.message.length
    ) {
      await firestore.collection("message").add({
        name: form.value.name,
        email: form.value.email,
        phone: form.value.phone,
        company: form.value.company,
        message: form.value.message,
      });
      emailjs
        .sendForm(
          "service_w2ra9kk",
          "template_yq28gx9",
          "#contact-form",
          "user_GMhoPw1R4j8TfJkac4GRa"
        )
        .then(
          (result) => {
            setEmailSendSeccess("The message was sent successfully");
            setForm({
              ...form,
              value: {
                name: "",
                email: "",
                phone: "",
                company: "",
                message: "",
              },
            });
            setEmailSendError("");
          },
          (error) => {
            setEmailSendError("Something went wrong, try again later");
          }
        );
    }
  };

  const formHandler = (e) => {
    setForm({
      ...form,
      value: { ...form.value, [e.target.name]: e.target.value },
      isDirty: {
        ...form.isDirty,
        [e.target.name]: true,
      },
    });
  };
  return (
    <Grid
      item
      md={size ? size : 7}
      className={classes.container}
      data-aos={home && "fade-up"}
      data-aos-delay="300"
      data-aos-duration="850"
    >
      <form className="contact-form" id="contact-form" onSubmit={sendEmail}>
        <Grid container justify="center" spacing={1}>
          <Grid item xs={12}>
            <Text
              variant={home ? "h3" : "h4"}
              className={classes.sectionTitle}
              align="left"
            >
              {"Schedule A Call Today"}
            </Text>
          </Grid>
          {/* Name */}
          <Grid item xs={12} style={{ height: 50 }}>
            <Input
              label="Name"
              value={form.value.name}
              onChange={(e) => formHandler(e)}
              name="name"
              error={form.errors.name ? true : false}
            />
            <div className={classes.menualErrorFix}>{form.errors.name}</div>
          </Grid>

          {/* Email */}
          <Grid item sm={6} xs={12} style={{ height: 50 }}>
            <Input
              label="Email"
              value={form.value.email}
              onChange={(e) => formHandler(e)}
              name="email"
              error={form.errors.email ? true : false}
            />
            <div className={classes.menualErrorFix}>{form.errors.email}</div>
          </Grid>

          {/* Phone */}
          <Grid item sm={6} xs={12} style={{ height: 50 }}>
            <Input
              label="Phone"
              value={form.value.phone}
              onChange={(e) => formHandler(e)}
              name="phone"
              error={form.errors.phone ? true : false}
            />
            <div className={classes.menualErrorFix}>{form.errors.phone}</div>
          </Grid>

          {/* Company */}
          <Grid item xs={12} style={{ height: 50 }}>
            <Input
              label="Company"
              value={form.value.company}
              onChange={(e) => formHandler(e)}
              name="company"
              error={form.errors.company ? true : false}
            />

            <div className={classes.menualErrorFix}>{form.errors.company}</div>
          </Grid>

          {/* Message */}
          <Grid item xs={12} style={{ height: 100 }}>
            <Input
              label={home ? "Message" : "How can we help?"}
              value={form.value.message}
              onChange={(e) => formHandler(e)}
              name="message"
              multiline
              rows={home ? 3 : 5}
              error={form.errors.message ? true : false}
            />

            <div className={classes.menualErrorFix}>{form.errors.message}</div>
          </Grid>

          {/* Submit Button */}
          <Grid container justify="center" className={classes.btnStyle}>
            <Button
              type="submit"
              className={classNames(classes.submitBtnStyle)}
              variant="contained"
              color={home ? "" : "secondary"}
            >
              Submit
            </Button>
            <div
              className={classes.menualErrorFix}
              style={{
                color: emailSendSeccess
                  ? theme.palette.common.successTextColor
                  : theme.palette.common.errorTextColor,
                marginTop: theme.palette.spacing,
              }}
            >
              {emailSendSeccess}
              {emailSendError}
            </div>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};
export default InputHandler;
