import { Grid } from "@material-ui/core";
import React from "react";
import { motion } from "framer-motion";
import ServiceCard from "./ServiceCard";
import calculateSpacing from "./calculateSpacing";

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.3,
    },
  },
};

const ServicesGrid = ({ width, services }) => {
  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="visible"
      className="container"
    >
      <Grid container spacing={calculateSpacing(width)} align="center">
        {services &&
          Object.entries(services).map(([key, value]) => (
            <Grid item xs={12} sm={6} md={4} key={key}>
              <ServiceCard
                icon={value.gridIcon}
                title={value.title}
                summary={value.summary}
                link={value.link}
                id={key}
              />
            </Grid>
          ))}
      </Grid>
    </motion.div>
  );
};

export default ServicesGrid;
