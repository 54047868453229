import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyItems: "conter",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  // Containers
  sectionContainer: {
    marginTop: (props) =>
      props.type === "AppDevelopment"
        ? props.content?.slider
          ? theme.palette.spacing * 60
          : theme.palette.spacing * 42
        : props.content?.slider
        ? theme.palette.spacing * 35
        : theme.palette.spacing * 40,

    width: "100%",
    backgroundColor: (props) => props.colors?.backgroundColor,
    [theme.breakpoints.up(1921)]: {
      marginTop: (props) =>
        !props.content?.slider && theme.palette.spacing * 48,
    },
    [theme.breakpoints.down(1921)]: {
      marginTop: (props) =>
        !props.content?.slider && theme.palette.spacing * 45,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: (props) => (props.type === "AppDevelopment" ? 0 : 0),
      marginBottom: theme.palette.spacing * 8,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.palette.spacing * 4,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.palette.spacing * 2,
    },
  },

  // description
  description: {
    backgroundColor: theme.palette.common.white,
    width: "35vw",
    marginLeft: (props) => (props.type === "AppDevelopment" ? "15%" : "10%"),
    paddingLeft: theme.palette.spacing * 2,
    paddingRight: theme.palette.spacing * 2,
    paddingBottom: theme.palette.spacing * 2,
    [theme.breakpoints.down("md")]: {
      width: "75% !important",
      marginLeft: (props) => !props.nothing && 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
      paddingRight: theme.palette.spacing,
      paddingLeft: theme.palette.spacing,
    },
  },
  extraSlider: {
    display: "none",
    backgroundColor: theme.palette.common.secondaryHover,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: theme.palette.spacing * 10,
      marginTop: -theme.palette.spacing * 10.7,
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  extra2: {
    display: "none",
    height: theme.palette.spacing * 20,
    backgroundColor: theme.palette.common.secondaryHover,
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: theme.palette.spacing * 20,
      marginTop: -theme.palette.spacing * 20,
      display: "block",
    },
  },
  image: {
    maxWidth: "100%",
    height: (props) => (props.type === "AppDevelopment" ? 450 : 350),
    display: "block",
    position: "absolute",

    marginTop: -theme.palette.spacing * 0.01,
    right: (props) => (props.type === "AppDevelopment" ? "15%" : "10%"),
    zIndex: 5,
    [theme.breakpoints.down("md")]: {
      position: "relative",
      marginLeft: "25%",
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.palette.spacing,
      paddingLeft: theme.palette.spacing,
      right: (props) => !props.nothing && 0,
      marginLeft: 0,
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: theme.palette.spacing,
      paddingLeft: theme.palette.spacing,
    },
  },
  titleContaier: {
    backgroundColor: (props) => props.colors?.backgroundColor,
    [theme.breakpoints.down("md")]: {
      backgroundColor: (props) => !props.nothing && theme.palette.common.white,
    },
  },
  title: {
    color: `${theme.palette.common.primaryHover} !important`,
    paddingTop: theme.palette.spacing * 2,
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: theme.palette.common.white,
    paddingLeft: theme.palette.spacing * 2,
    marginLeft: (props) => (props.type === "AppDevelopment" ? "15%" : "10%"),
    width: "35vw",
    [theme.breakpoints.down("md")]: {
      marginLeft: (props) => !props.nothing && theme.palette.spacing * 2,
      paddingLeft: 0,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: (props) => !props.nothing && 0,
      paddingLeft: theme.palette.spacing,
    },
  },
  body: {
    color: `${theme.palette.common.primaryHover} !important`,
    paddingTop: theme.palette.spacing * 2,
    [theme.breakpoints.down("md")]: {
      fontSize: "0.90rem",
    },
  },
  sliderImage: {
    maxWidth: "100%",
    height: (props) => (props.type === "AppDevelopment" ? 500 : 350),
    display: "block",
    position: "relative",
    objectFit: "contain",
    [theme.breakpoints.down("xs")]: {
      height: (props) => (props.type === "AppDevelopment" ? 500 : 250),
    },
  },
  container: {
    position: "absolute",
    width: (props) => (props.type === "AppDevelopment" ? 320 : 720),
    margin: (props) => (props.type === "AppDevelopment" ? 0 : -6),
    [theme.breakpoints.up("lg")]: {
      width: (props) => (props.type === "AppDevelopment" ? 320 : 720),
      right: (props) => (props.type === "AppDevelopment" ? "15%" : "10%"),
    },
    [theme.breakpoints.down("lg")]: {
      width: (props) => props.type !== "AppDevelopment" && 620,
      margin: (props) => (props.type === "AppDevelopment" ? 0 : -6),
    },
    [theme.breakpoints.down("md")]: {
      width: (props) => props.type !== "AppDevelopment" && 720,
      margin: (props) => props.type === "AppDevelopment" && 5,
      position: "relative",
      right: "1%",
      marginTop: theme.palette.spacing * 5,
    },
    [theme.breakpoints.down("sm")]: {
      width: (props) => props.type !== "AppDevelopment" && "80%",
      margin: (props) => props.type === "AppDevelopment" && -1,
    },
    [theme.breakpoints.down("xs")]: {
      width: (props) => props.type !== "AppDevelopment" && "95%",
    },
  },
}));

export default useStyles;
