import React, { useEffect } from "react";
import { Box, Container } from "@material-ui/core";
import { ParallaxBanner } from "react-scroll-parallax";
import Button from "../Button";
import { Text, TypeWriterEffect } from "..";
import AOS from "aos";
import "aos/dist/aos.css";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../../theme";
import { list } from "../../Pages/aboutUs/CountSection";
import { Link } from "react-router-dom";
import InputHandler from "../Form/InputHandler";
import { Grid } from "@material-ui/core";
import Count from "../countUp/Count";
import { Hidden } from "@material-ui/core";
import HeroVideoPoster from "../../images/heroVideoPoster.png";
import Video from "../../images/hero.mp4";
import style from "./style";

function VideoOverlay(props) {
  const {
    title,
    subTitle,
    buttonOneText,
    buttonOneLink,
    btnBackgroundColor,
    typed,
  } = props;
  const classes = style(props);
  useEffect(() => {
    AOS.init({
      offset: 1200,
      duration: 700,
      easing: "linear",
    });
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.heroContainer}>
        <ParallaxBanner
          className={classes.bannerBg}
          layers={[
            {
              amount: 0.3,
              children: (
                <video
                  className={classes.video}
                  autoPlay
                  loop
                  playsInline
                  preload="auto"
                  muted
                  poster={HeroVideoPoster}
                  src={Video}
                />
              ),
            },
          ]}
        >
          <div className={classes.parallaxChildren}>
            <Box className={classes.overlayEffect}>
              <Container maxWidth="lg" spacing={1}>
                <Grid maxWidth="lg" container>
                  <Grid item md={8} className={classes.leftContentWraper}>
                    <Box className={classes.heroContent}>
                      <Text
                        data-aos="fade-up"
                        data-aos-duration="700"
                        variant={"h3"}
                        className={classes.title}
                      >
                        {title}
                      </Text>
                      {typed && <TypeWriterEffect data-aos="fade-up" />}
                      <Text
                        data-aos="fade-up"
                        data-aos-delay="300"
                        data-aos-duration="750"
                        variant="h3"
                        className={classes.subTitle}
                      >
                        {subTitle}
                      </Text>
                      {buttonOneText && (
                        <>
                          <Button
                            data-aos="fade-up"
                            data-aos-duration="800"
                            data-aos-delay="350"
                            variant="contained"
                            backgroundColor={btnBackgroundColor}
                            className={classes.btn}
                            size="small"
                          >
                            <Link to={buttonOneLink} style={{ color: "white" }}>
                              {buttonOneText}
                            </Link>
                          </Button>
                        </>
                      )}
                    </Box>
                  </Grid>
                  <InputHandler
                    size={4}
                    className={classes.formContainer}
                    home
                  />
                </Grid>
              </Container>
            </Box>
          </div>
        </ParallaxBanner>
        <Hidden xsDown>
          <Container justify="center" className={classes.summary}>
            <Grid maxWidth="lg" container>
              {list &&
                list
                  .slice(0, 3)
                  .map(({ icon, countNumber, label, summary }, index) => (
                    <Grid
                      item
                      md={4}
                      sm={6}
                      xs={12}
                      style={{ marginBottom: 10 }}
                      key={index}
                    >
                      <Box display="flex">
                        <Box className={classes.summaryIcon}>{icon}</Box>
                        <Box>
                          <Box display="flex" alignItems="baseline">
                            <Text variant="h2" className={classes.counter}>
                              <Count end={countNumber} />
                            </Text>
                            <Text variant="h5" className={classes.summaryLabel}>
                              {label}
                            </Text>
                          </Box>
                          <Box display="flex">
                            <Text
                              variant="h5"
                              className={classes.summaryDescription}
                            >
                              {summary}
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
            </Grid>
          </Container>
        </Hidden>
      </Box>
    </ThemeProvider>
  );
}

export default VideoOverlay;
