import React from "react";
import ImageOverlay from "../../Components/ImageOverlay";
import { Box } from "@material-ui/core";
import { useStyle } from "./Utility";
import theme from "../../theme";
import { ThemeProvider } from '@material-ui/core/styles';
import services from './../../images/services.png'


const Header = ({ contents }) => {
  const style = useStyle();
  return (
    <ThemeProvider theme={theme}>
      <Box className={style.height}>
        <ImageOverlay
          image={services}
          title={contents.title}
          subTitle={contents.subTitle}
          buttonOneText="Contact Us"
          buttonOneLink="/contact"
          overlayFalse={true}
          btnBackgroundColor={'#02334d'}
        />
      </Box>
    </ThemeProvider>
  );
};

export default Header;
