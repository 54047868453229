import ContactInfo from "./ContactInfo";
import style from "./style";
import InputHandler from "./InputHandler";
import { Grid, Container } from "@material-ui/core";

const Form = () => {
  const classes = style();
  return (
    <Container maxWidth="md" justify="center">
      <Grid container spacing={3} className={classes.container}>
        <InputHandler />
        <ContactInfo />
      </Grid>
    </Container>
  );
};
export default Form;
