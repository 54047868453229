import React from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { makeStyles } from "@material-ui/core/styles";
import { motion } from "framer-motion";
import { Text } from "../../Components";
import { Link } from "react-router-dom";
import { Box } from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

const useStyle = makeStyles((theme) => ({
  cardWrapper: {
    backgroundColor: theme.palette.white.main,
    boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.16) !important",
    height: 350,
    maxWidth: 330,
    borderTopLeftRadius: 30,
    borderBottomRightRadius: 30,
    textAlign: "left",
  },

  thumbnailContent: {
    backgroundColor: (props) =>
      props.id % 2 === 0
        ? theme.palette.common.imageOverlayBlue
        : theme.palette.common.imageOverlayRed,
    height: 120,
    borderTopLeftRadius: 30,
    display: "flex",
    alignItems: "center",
  },

  thumbnailTitle: {
    color: theme.palette.white.main,
    fontWeight: theme.typography.fontWeightBold,
  },

  heading: {
    textTransform: "capitalize",
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: "center",
    paddingTop: theme.palette.spacing * 0.4,
    paddingBottom: theme.palette.spacing * 1.8,
  },

  media: {
    height: 120,
    borderTopLeftRadius: 30,
  },
  content: {
    height: 190,
    overflow: "hidden",
  },

  summary: {
    lineHeight: 1.3,
  },

  cardAction: {
    justifyContent: "flex-end",
  },

  learnMore: {
    color: theme.palette.common.primaryHover,
    fontWeight: theme.typography.fontWeightMedium,
    cursor: "pointer",
    textDecoration: "none",
    "& a": { color: theme.palette.common.primaryHover },
    "& svg": { height: 13, verticalAlign: "middle", marginLeft: -7 },
  },
}));

const ServiceCard = (props) => {
  const item = {
    hidden: { y: 10, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const { title, summary, icon, link } = props;

  const classes = useStyle(props);
  return (
    <motion.div
      variants={item}
      transition={{ duration: 0.5 }}
      className={classes.cardWrapper}
    >
      <CardMedia className={classes.media} image={icon} title={title}>
        <Box
          className={classes.thumbnailContent}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Text variant="h5" className={classes.thumbnailTitle}>
            {title}
          </Text>
        </Box>
      </CardMedia>
      <CardContent className={classes.content}>
        <Text variant="h5" className={classes.heading}>
          Top {title} service on demand
        </Text>
        <Text variant="body1" className={classes.summary}>
          {summary}
        </Text>
      </CardContent>

      <CardActions className={classes.cardAction}>
        {link && (
          <Text className={classes.learnMore} variant="body1">
            <Link to={`/services${link}`}>
              Read more <ArrowForwardIosIcon />
            </Link>
          </Text>
        )}
      </CardActions>
    </motion.div>
  );
};

export default ServiceCard;

// <Box className={classes.imageWrapper}>
// {/* <ReactSVG src={icon} className={classes.vector} /> */}
// {/* <img
//   src="https://res.cloudinary.com/dv244dkbr/image/upload/v1622407628/MzTechCorp/services/thumbnail/service_web_design_oiltxo.webp"
//   className={classes.thumbnail}
//   alt="web design"
// /> */}
// </Box>
// <Text variant="h5" className={classes.heading}>
// {title}
// </Text>
// <Text variant="body1">{summary}</Text>
// {link && (
// <Text className={classes.learnMore} variant="body1">
//   <span>
//     <Link className={classes.learnMore} to={`/services${link}`}>
//       Read more <ArrowForwardIosIcon />
//     </Link>
//   </span>
// </Text>
// )}
