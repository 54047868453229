import { useState, useMemo } from 'react';
import {Text, Input, Button} from '../../../Components/index'
import useStyles from './style'
import { useForm } from "react-hook-form";
import { Grid, Select, MenuItem } from '@material-ui/core';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useDropzone} from 'react-dropzone'
import { ReactSVG } from "react-svg";



const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const schema = yup.object().shape({
    legalName: yup.string().required('Legal Name is a required field'),
    email: yup.string().email("Please provide a valid Email.").required("Email is a required field"),
    phone: yup.string().min(9).matches(phoneRegExp, 'Phone number is not valid').required("Phone is a required field"),
});



const FormBody = (props) => {
    const {resume} = props
    const classes = useStyles(props)
    const [countryCode, setCountryCode] = useState('+880 (BD)')
    // const [fileInfo, setFileInfo] = useState({})
    const { register, handleSubmit, formState: {errors}  } = useForm({resolver: yupResolver(schema)});
    const getFormData = (data) => {
        const info = {...data, countryCode}
        console.log(info); 
        console.log(files)
        
    }
    console.log("errors",errors)

    // Drag-n-Drop
    // const onDrop = useCallback(acceptedFiles => {
    //     // setFileInfo(fileInfo.concat(...acceptedFiles));
    //     setFileInfo(
    //         acceptedFiles.map(file => {
    //             Object.assign(file, {
    //                 name: file.name
    //             })
    //         })
    //     )
    // }, [fileInfo]);
    
    const baseStyle = {
        marginLeft: '20px',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: 'transparent',
        borderStyle: 'dashed',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const activeStyle = {
        borderColor: '#2196f3'
    };
      
    const acceptStyle = {
        borderColor: '#000'
    };
      
    const rejectStyle = {
        borderColor: '#ff1744'
    };
    const {
        getRootProps, 
        getInputProps, 
        acceptedFiles,     
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({noClick: true});

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), 
     // eslint-disable-next-line
      [
        isDragActive,
        isDragReject,
        isDragAccept
      ]);
    const files = acceptedFiles.map(file => <li className={classes.fileName} key={file.path}>{file.path}</li>);
    // acceptedFiles.map(file => setFileInfo(file))
    // console.log(fileInfo)

    return (
        <div className={classes.container}>

            <Text
                variant="h4"
                className={classes.requireTitle}
                align="right"
            >
                {'*Required'}
            </Text> 
            <Text
                variant="h4"
                className={classes.jobTitle}
            >
                {'Resume'}
                <span className={classes.resumeTitleSpan}>
                        {'*'}
                </span> 
            </Text> 

            <section className={classes.fileContainer}>
                <div className={classes.dragAndDrop}> 
                    <ReactSVG src={resume?.imageUrl} />
                    <div {...getRootProps({className: 'dropzone', style})} className={classes.dndContainer}>
                        <input {...getInputProps()} />
                        <p className={classes.dragTitle}>Drag and drop files here</p>
                        {files.length === 0 ? (
                            <p  className={classes.fileNamePlaceholder} >File Name</p>
                        ) : (
                            <div classname={classes.list}>
                                {files}
                            </div>
                        )}
                    </div>
                </div>
                <p className={classes.or}> Or </p>
                <Button 
                    variant="contained" 
                    backgroundColor="#48484a"
                    textColor="#fffdfd"
                    className={classes.btnUpload}
                >
                    Browse Files
                </Button>
            </section>


            <Text
                variant="h4"
                className={classes.jobTitle}
            >
                {'Contact details'}
            </Text> 

            <form onSubmit={handleSubmit(getFormData)}>

                <Grid item>
                    <Input
                        error={errors.legalName?.message}
                        label="Legal Name *"
                        name="legalName"
                        {...register('legalName')}
                    />
                    <div className={classes.menualErrorFix}>
                        {errors.legalName?.message && <p>{errors.legalName?.message}</p>}
                    </div>
                </Grid>
                <Grid container justify="space-between" spacing={3} >
                    <Grid item sm={6} xs={12}>
                        <Input
                            error={errors.email?.message}
                            label="Email *"
                            name="email"
                            style={{marginTop: 16}}
                            {...register('email')}
                        />
                        <div className={classes.menualErrorFix}>
                            {errors.email?.message && <p>{errors.email?.message}</p>}
                        </div>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <Grid container direction='row' alignItems='flex-end' justify='space-between'>
                            <Grid item xs={4}>
                                <Select 
                                    value={countryCode} 
                                    onChange={(e) => setCountryCode(e.target.value)} 
                                    className={classes.select}
                                >
                                    <MenuItem value="+880 (BD)">+880 (BD)</MenuItem>
                                    <MenuItem value="+1 (US)">+1 (US)</MenuItem>
                                </Select>
                                <div className={classes.menualErrorFix}></div>
                            </Grid>
                            <Grid item xs={8}>
                                <Input
                                    error={errors.phone?.message}
                                    label="Phone *"
                                    name="phone"
                                    style={{marginTop: 16}}
                                    {...register('phone')}
                                />
                                <div className={classes.menualErrorFix}>
                                    {errors.phone?.message && <p>{errors.phone?.message}</p>}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Input
                        label="Address"
                        name="address"
                        {...register('address')}
                    />
                    <div className={classes.menualErrorFix}></div>
                </Grid>
                <Grid item>
                    <Input
                        label="Website"
                        name="website"
                        {...register('website')}
                    />
                    <div className={classes.menualErrorFix}></div>
                </Grid>

                <Text 
                    className={classes.jobTitle}
                    variant='h4'
                > 
                    {'Add cover letter'} 
                    <span className={classes.jobTitleSpan}>
                        {'(optional)'}
                     </span> 
                </Text>

                <Grid item>
                    <Input
                        label="write your optional cover letter here"
                        name="coverLatter"
                        multiline
                        rows={1}
                        rowsMax={15}
                        {...register('coverLatter')}
                    />
                    <div className={classes.menualErrorFix}></div>
                </Grid>

                <Grid container justify='flex-start' className={classes.btns}>
                    <Button 
                        variant="contained" 
                        color="primary" 
                        type="submit"
                        backgroundColor="#02334d"
                        className={classes.submitBtn}
                    >
                        Submit
                    </Button>

                    <Button 
                        variant="contained" 
                        type="submit"
                        borderColor="#02334d"
                        backgroundColor="transparent"
                        textColor="#02334d"
                    >
                        Discard
                    </Button>
                </Grid>

            </form> 
        </div>
    )
}
export default FormBody