import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  navSelected: {
    color: `${theme.palette.secondary.main} !important`,
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 60,
    height: 60,
    borderRadius: "50%",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.white.main,
  },
}));

export default useStyles;
