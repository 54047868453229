import { Text } from "./../";
import style from "./style";

const Section = ({ title, variant }) => {
  const classes = style();
  return (
    <Text className={classes.sectionTitle} variant={variant ? variant : "h2"}>
      {title}
    </Text>
  );
};
export default Section;
