import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../../theme";
import VideoOverlay from "../../Components/VideoOverlay/index";

const Header = () => {
  return (
    <ThemeProvider theme={theme}>
      <VideoOverlay
        title="Ready to initiate your digital transformation? We are."
        subTitle="We utilize cutting edge resources to implement our development procedures."
        showButton
        buttonOneText="View Recent Work"
        buttonOneLink="/portfolio"
        buttonTwoText="Contact Us"
        typed={true}
        bgColor="rgb(2 51 77 / 70%)"
      />
    </ThemeProvider>
  );
};

export default Header;
