import React from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import { Text } from "../../Components";

const useStyle = makeStyles((theme) => ({
  contentLeftContainer: {
    width: 440,
    height: "auto",
    position: "absolute",
    top: 25,
    left: -75,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      top: -60,
      left: 20,
      width: 320,
    },
  },
  contentRightContainer: {
    width: 460,
    height: "auto",
    position: "absolute",
    top: 25,
    right: -95,

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      top: -60,
      left: 20,
      width: 320,
    },
  },
  container: {
    width: 370,
    height: 280,
    border: `3px solid ${theme.palette.common.primaryHover}`,
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      marginTop: 40,
      height: 290,
    },
  },
  name: { fontWeight: theme.typography.fontWeightMedium, marginTop: 4 },
  leftQuote: {
    transform: "rotate(180deg) !important",
  },
  rightQuote: {
    verticalAlign: "middle",
  },
  imageWrapper: {
    padding: "0 8px !important",
    marginTop: 8
  },
  imageContainer: {
    width: 165,
    height: 160,
    paddingBottom: 0,
    objectFit: "cover",
    border: `3px solid ${theme.palette.common.primaryHover}`,
    borderRadius: 10,

    [theme.breakpoints.down("sm")]: {
      width: 120,
      height: 120,
    },
  },
  quote: {
    display: "inline",
  },
}));

const Message = ({ content, direction = "row" }) => {
  const style = useStyle();
  return (
    <Box className={style.container}>
      <Grid
        container
        alignItems="center"
        direction={direction}
        className={
          direction === "row"
            ? style.contentLeftContainer
            : style.contentRightContainer
        }
        spacing={2}
      >
        <Grid item xs={12} sm={5} className={style.imageWrapper}>
          <img
            className={style.imageContainer}
            src={content.imageUrl}
            alt="photos"
          />
        </Grid>
        <Grid item xs={12} sm={7}>
          <Box display="flex" flexDirection="column">
            <Text variant="body2" display="inline">
              <FormatQuoteIcon className={style.leftQuote} /> {content.message}
              <FormatQuoteIcon className={style.rightQuote} />
            </Text>
            <Box>
              <Text variant="h3" className={style.name}>
                {content.name}
              </Text>
            </Box>
            <Box>
              <Text variant="body1">{content.designation} | MzTech Corp</Text>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Message;
