import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import { makeStyles } from "@material-ui/core/styles";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

const useStyle = makeStyles((theme) => ({
  titleBold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  link: {
    "& a": {
      margin: "2px 0",
      color: `${theme.palette.common.primaryHover} !important`,
      "&:hover": {
        color: `${theme.palette.common.secondaryHover} !important`,
      },
    },
  },
  underline: {
    width: "100%",
    maxWidth: 700,
    borderBottom: `1px solid ${theme.palette.common.primaryHover}`,
  },
  logo: {
    "& svg": {
      height: 30,
      width: "auto",
      marginLeft: -2,
      display: "block",
    },
  },
  faceBookColor: {
    color: theme.palette.socilIconColor.fbColor,
    borderRadius: 5,
    cursor: "pointer",
  },
  linkedInColor: {
    color: theme.palette.socilIconColor.linkedin,
    borderRadius: 5,
    cursor: "pointer",
  },
}));

const footerSectionOne = {
  text: "Our customer-first approach is geared to fuel fresh narratives about technology. We craft digital value to help customers worldwide rise above their competition.",
  linkedIn: {
    icon: LinkedInIcon,
    url: "https://www.linkedin.com/company/mztech-corp/mycompany/",
  },
  faceBook: {
    icon: FacebookIcon,
    url: "https://www.facebook.com/mztechcorp",
  },
};
const footerSectionTwo = {
  title: "Our Services",
  serviecs: [
    {
      text: "Ecommerce",
      link: "#",
    },
    {
      text: "Website Migration",
      link: "#",
    },
    {
      text: "Search Engine Optimization",
      link: "#",
    },
    {
      text: "Web Design Services",
      link: "#",
    },
    {
      text: "Web Security Services",
      link: "#",
    },
    {
      text: "Website Speed Optimization",
      link: "#",
    },
    {
      text: "Learning Management Systems",
      link: "#",
    },
    {
      text: "Digital Marketion service",
      link: "#",
    },
    {
      text: "Software Development",
      link: "#",
    },
    {
      text: "App Development",
      link: "#",
    },
  ],
};
const footerSectionThree = {
  title: "Legal",
  serviecs: [
    {
      text: "Privacy Policy",
      link: "/legal#privacy-policy",
    },
    {
      text: "Cookie Policy",
      link: "/legal#cookie-policy",
    },
    {
      text: "Terms & Conditions",
      link: "/legal#terms-and-conditions",
    },
    {
      text: "FAQ",
      link: "/legal#faq",
    },
  ],
};
const footerSectionFour = {
  title: "Contract Info",
  info: [
    {
      text: "8171 Harrison St., New York, NY",
      icon: <LocationOnIcon fontSize="small" />,
    },
    {
      text: "+1 (929) 383-0818",
      icon: <PhoneIcon fontSize="small" />,
    },
    {
      text: "info@mztech.us‬",
      icon: <EmailIcon fontSize="small" />,
    },
  ],
};
const footerSectionFive = {
  bottonText: "MzTech Software Development Corporation. All rights reserved ",
};

export {
  footerSectionOne,
  footerSectionTwo,
  footerSectionThree,
  footerSectionFour,
  footerSectionFive,
  useStyle,
};
