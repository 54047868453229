import React from "react";
import ImageOverlay from "../../Components/ImageOverlay";
import { Box } from "@material-ui/core";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import aboutUs from './../../images/img1.jpg'
import theme from "../../theme";

const useStyle = makeStyles((theme) => ({
  height: {
    height: 400,
    [theme.breakpoints.down("md")]: {
      height: 300,
    },
  },
}));

const content = {
  imageUrl:
    "https://res.cloudinary.com/dv244dkbr/image/upload/v1620544602/MzTechCorp/Our_service_image_overlay_frwikg.jpg",
  title: "An Innovative Software Development Corporation",
  subTitle: "",
};
const Header = () => {
  const style = useStyle();
  return (
    <ThemeProvider theme={theme}>
      <Box className={style.height}>
        <ImageOverlay
          image={aboutUs}
          title={content.title}
          subTitle={content.subTitle}
          bgColor={theme.palette.common.imageOverlayBlue}
        />
      </Box>
    </ThemeProvider>
  );
};

export default Header;
