import React,{useEffect} from 'react'
import {Grid,Container,Box} from '@material-ui/core'
import AOS from 'aos';
import 'aos/dist/aos.css';

const BodyCard = ({children,src,direction='row',className}) => {

    useEffect(()=>{

        AOS.init({
            offset: 200,
            duration: 600,
            easing: 'ease-in-sine',
            delay: 100,
          });
    },[])
    return (
        <Container>
            <Grid container justify='center' alignItems='center' >
                <Grid container item xs direction={direction} alignItems='center' spacing={2}>
                <Grid  item xs={12} md={6} >
                    <Box pb={3} data-aos={direction === 'row' ? "fade-right" : "fade-left"} display='flex' alignItems='center' >
                    {children}
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}   >
                    <Box data-aos={direction === 'row' ? "fade-left" : "fade-right"} className={className}>
                    <img  src={src} alt='photos' className={className}/>
                    </Box>
                </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default BodyCard
