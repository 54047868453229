import { Box, Container, Tabs, Tab, Hidden, Grid } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AOS from "aos";
import { Text } from "../../../Components";
import { useStyle } from "./Utility";
import GalleryView from "../../../Components/carousel/GalleryView";
import calculateSpacing from "../../Services/calculateSpacing";

const Portfolio = ({ content, width }) => {
  const [value, setValue] = React.useState(0);
  useEffect(() => {
    AOS.init({
      duration: 400,
      easing: "ease-in",
    });
  }, [value]);
  const classes = useStyle();

  const returnSubsetOfProject = (dataToFilter) =>
    Object.fromEntries(
      Object.entries(dataToFilter).filter(([key], index) => index < 8)
    );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className={classes.wrapper} pb={4}>
      <Box py={3}>
        <Container>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Text display="inline" variant="h3" className={classes.titleBold}>
                Our{" "}
                <Text
                  display="inline"
                  variant="h3"
                  className={classes.titleColor}
                >
                  Work
                </Text>
              </Text>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Tabs value={value} onChange={handleChange}>
                <Tab
                  label="Gallery View"
                  className={classes.viewTab}
                  disableRipple
                />
                <Tab
                  label="List View"
                  className={classes.viewTab}
                  disableRipple
                />
              </Tabs>
              <Link to="/portfolio">
                <Text>Show all</Text>
              </Link>
              <Hidden smDown>
                <ChevronRightIcon />
              </Hidden>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box>
        <Container>
          {value === 0 ? (
            <Grid container spacing={calculateSpacing(width)} align="center">
              {content &&
                Object.entries(returnSubsetOfProject(content)).map(
                  ([key, item]) => (
                    <Grid item xs={12} sm={6} md={3} key={key}>
                      <>
                        <img
                          className={classes.gridImageContainer}
                          src={item?.squareImageUrl}
                          alt={item?.title}
                        />
                        <Text
                          className={classes.projectLink}
                          variant="h5"
                          onClick={() => window.open(`${item?.link}`, "_blank")}
                        >
                          <VisibilityIcon />
                          View Project
                        </Text>
                      </>
                    </Grid>
                  )
                )}
            </Grid>
          ) : (
            <GalleryView content={content} />
          )}
        </Container>
      </Box>
    </Box>
  );
};

export default Portfolio;
