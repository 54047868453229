import titleImage from "../../images/title.png";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    color: `${theme.palette.common.primaryHover} !important`,
    textAlign: "center",
    padding: theme.palette.spacing * 2,
    margin: theme.palette.spacing * 3,
    fontWeight: theme.typography.fontWeightRegular,
    [theme.breakpoints.down("md")]: {
      padding: theme.palette.spacing * 2.5,
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.palette.spacing * 2,
    },
    backgroundImage: `url(${titleImage})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
}));
export default useStyles;
