import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  quill: {
    height: '100px',
    marginBottom: '40px',
    paddingBottom: '10px',
  },
}));

const ReactQuillEditor = (props) => {
  const classes = useStyles();
 
  return (
    <Box data-testid="custom-element" style={{ margin: 'auto' }}>
      <ReactQuill
        name={props.name}
        className={classes.quill}
        value={props.text || ''}
        placeholder={props.placeholder}
        id={props.id}
        onChange={props.handleChange}
      />
    </Box>
  );
};

export default ReactQuillEditor;
