import React from "react";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Text from "../../Components/text/Text";
import { motion } from "framer-motion";
import { makeStyles, Box } from "@material-ui/core";
import {Link} from 'react-router-dom'

const useStyle = makeStyles((theme) => ({
  cardWrapper: {
    backgroundColor: theme.palette.white.main,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    height: 285,
    textAlign: "left",
  },

  image: {
    width: "100%",
    objectFit: "cover",
    height:80,
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },

  heading: {
    margin: "13px 0",
    textTransform: "capitalize",
    fontWeight: theme.typography.fontWeightMedium,
  },

  applyWrapper: { paddingTop: theme.palette.spacing * 2 },

  apply: {
    color: theme.palette.common.primaryHover,
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": { fontWeight: theme.typography.fontWeightMedium },
    "& svg": { verticalAlign: "middle", marginLeft: -3 },
  },
}));

const item = {
  hidden: { y: 10, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const CareerCard = ({ link, title, imageUrl, summary, teamCard }) => {
  const classes = useStyle();
  return (
    <motion.div
      variants={item}
      transition={{ duration: 0.5 }}
      className={classes.cardWrapper}
      style={teamCard ? { marginRight: 10 } : ""}
    >
      <div className="image-wrapper">
        <img className={classes.image} src={imageUrl} alt={title} />
      </div>
      <Box mx={2}>
        <Text variant="h5" className={classes.heading}>
          {title}
        </Text>
        <Text variant="body2" dangerouslySetInnerHTML={{ __html: summary}} />
        <Text variant="body2" className={classes.applyWrapper}>
          {link && (
            <span>
              <Link className={classes.apply} to={link}>
                {teamCard ? "See Jobs" : "Apply now" }  <ArrowRightAltIcon />
              </Link>
            </span>
          )}
        </Text>
      </Box>
    </motion.div>
  );
};

export default CareerCard;
