import React from "react";
import ImageOverlay from "../../Components/ImageOverlay";
import theme from "../../theme";
import { ThemeProvider } from "@material-ui/core/styles";
import services from './../../images/services.png'

const Header = () => {
  return (
    <ThemeProvider theme={theme}>
      <ImageOverlay
        image={services}
        title="Our Services"
        subTitle="We support IT service industries across North America. Our marketplace includes thousands of qualified service providers with a range of skillsets ready to help you get work done."
        overlayFalse={true}
      />
    </ThemeProvider>
  );
};

export default Header;
