import { makeStyles } from "@material-ui/core";
import {Text} from '../../Components'

const useStyles = makeStyles((theme) => ({
    sectionHeading: {
        marginTop: theme.palette.spacing*5,
        fontWeight: theme.typography.fontWeightMedium
    }
}));
const Title = (props) => {
    const classes = useStyles(props);
    const {title, id} = props
    return (
        <Text
            variant='h2'
            align='left'
            key={id}
            className={classes.sectionHeading}
        >
            {title}
        </Text>
    )
}
export default Title