import React, { Component } from "react";
import Slider from "react-slick";
import { Box, Fab } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Text from "../text/Text";
import "./style.css";
export default class GalleryView extends Component {
  state = {
    slideIndex: 0,
    updateCount: 0,
  };

  render() {
    const SimplePrevArrow = ({ className, style, onClick }) => (
      <Box
        style={{
          ...style,
          width: 40,
          height: 40,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          left: 0,
          borderRadius: 50,
          zIndex: 1,
          boxShadow: `0 0 3px`,
          transition: "linear .2s",
          backgroundColor: "rgb(255, 255, 255)",
          color: "rgb(2, 51, 77)",
          "& svg": {
            marginLeft: -5,
          },
        }}
        onClick={onClick}
        className={className}
      >
        <ChevronLeftIcon />
      </Box>
    );

    const SimpleNextArrow = ({ className, style, onClick }) => (
      <Box
        style={{
          ...style,
          width: 40,
          height: 40,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          right: 0,
          borderRadius: 50,
          boxShadow: `0 0 3px`,
          transition: "linear .2s",
          backgroundColor: "rgb(255, 255, 255)",
          color: "rgb(2, 51, 77)",
        }}
        onClick={onClick}
        className={className}
      >
        <ChevronRightIcon />
      </Box>
    );

    const settings = {
      centerMode: true,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      afterChange: () =>
        this.setState((state) => ({ updateCount: state.updateCount + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
      nextArrow: <SimpleNextArrow />,
      prevArrow: <SimplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 770,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    const content = this.props.content;
    const length = content && Object.values(this.props.content).length;
    console.log(length);
    return (
      <div data-aos="fade">
        <Slider
          ref={(slider) => (this.slider = slider)}
          {...settings}
          className="arrow-button"
        >
          {content &&
            content &&
            Object.entries(content).length > 0 &&
            Object.entries(content).map(([key, item]) => (
              <>
                <img
                  className="image"
                  src={item?.roundedImageUrl}
                  alt="Portfolio Images"
                />

                <Fab
                  variant="extended"
                  color="secondary"
                  className="visit-project"
                  onClick={() => window.open(`${item?.link}`, "_blank")}
                >
                  <VisibilityIcon
                    style={{
                      fontSize: 17,
                      marginRight: 5,
                    }}
                  />
                  View Project
                </Fab>
              </>
            ))}
        </Slider>
        <Box className="range">
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <input
              type="range"
              min={0}
              max={content && Object.values(this.props.content).length - 1}
              onChange={(e) => this.slider.slickGoTo(e.target.value)}
              value={this.state.slideIndex}
            />
            <Text variant="h4" style={{ marginLeft: 40 }}>
              {this.state.slideIndex + 1}
            </Text>
          </Box>
        </Box>
      </div>
    );
  }
}
