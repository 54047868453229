import React, { useEffect } from "react";
import Header from "./Header";
import ServiceDescription from "./ServiceDescription";
import OfferList from "./OfferList";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import TechnologyUsed from "./TechnologyUsed";
import ProjectImage from "./ProjectImage";
import useFetch from "../../Helpers/customHooks/useFetch";
import { generateId } from "../../Helpers/helperFunctions";
import smoothScrollTop from "../../Helpers/smoothScrollTop";
import GetInTouch from "./GetInTouch";

export const useStyle = makeStyles((theme) => ({
  body: {
    backgroundColor: theme.palette.common.white,
  },
}));

const ServiceDetailPage = () => {
  const classes = useStyle();
  const path = useParams();
  const id = generateId(path.service);
  const pageContent = useFetch();
  useEffect(() => {
    document.title = `MzTech - Services`;
    smoothScrollTop();
  }, [id]);

  return (
    <div className={classes.body}>
      <Header
        colors={pageContent?.[id]?.[0]}
        content={pageContent?.[id]?.[1]}
      />
      <ServiceDescription
        colors={pageContent?.[id]?.[0]}
        content={pageContent?.[id]?.[2]}
        height={320}
        type={id}
      />
      <OfferList
        colors={pageContent?.[id]?.[0]}
        content={pageContent?.[id]?.[3]}
      />
      <ProjectImage
        content={pageContent?.[id]?.[4]}
        title={pageContent?.[id]?.[1]?.title}
      />
      <TechnologyUsed content={pageContent?.[id]?.[5]} bgColor="#fff" />
      <ServiceDescription
        colors={pageContent?.[id]?.[0]}
        content={pageContent?.[id]?.[6]}
        height={200}
        type={id}
      />

      <TechnologyUsed content={pageContent?.[id]?.[7]} bgColor="#fff" />

      <GetInTouch id={path.service} />
    </div>
  );
};

export default ServiceDetailPage;
