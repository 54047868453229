import { makeStyles } from "@material-ui/core/styles";

export const useStyle = makeStyles((theme) => ({
  titleBold: {
    fontWeight: theme.typography.fontWeightMedium,
  },

  summary: {
    marginBottom: theme.spacing(4),
    fontSize: "1.3rem !important",
    fontWeight: 200,
  },
}));

export const contents = [
  {
    title: "Our Technology Partners",
    imageUrl: [
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1621309519/MzTechCorp/clientLogos/interface_pnezuy.svg",
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1621307596/MzTechCorp/clientLogos/technicolor-sound_exfjoh.svg",
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1621307596/MzTechCorp/clientLogos/baltika-4_tdsely.svg",
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1621307596/MzTechCorp/clientLogos/agroekonomik_mzcxqp.svg",
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1621307598/MzTechCorp/clientLogos/condor-earth-technologies_xurmr9.svg",
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1621307598/MzTechCorp/clientLogos/informatika_fqq21j.svg",
    ],
  },
];
