import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
    container: {
        paddingTop: theme.palette.spacing*5,
        backgroundColor: theme.palette.common.greyishBackground,
    },
    title:{
        color: `${theme.palette.common.primaryHover} !important`,
        fontWeight: theme.typography.fontWeightMedium,
        padding: theme.palette.spacing*5,
        textAlign: 'center'
    },
    girdContainer: {
        margin: theme.palette.spacing,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}));

export default useStyle