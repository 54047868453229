import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme => ({
    header: {
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
        padding: theme.palette.spacing*3,
        margin: 0,
        position: 'relative',
        zIndex: 5
    },
    Container: {
        minWidth: '80%',
        padding: 0,
        marginBottom: theme.palette.spacing*3,
        backgroundColor: theme.palette.common.white
    },
    form: {
        backgroundColor: theme.palette.common.white,
    },
}))

export default useStyles