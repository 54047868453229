import { Grid } from '@material-ui/core'
import style from './style'
import {Text} from '../../../../Components'
import { ReactSVG } from "react-svg";


const OfferList = (props) => {
    const classes = style(props)
    const {icon, facility} = props
    return(

        <Grid item xs={6} sm={4} md ={3} className={classes.container}>
            <ReactSVG 
                src={icon}
                className={classes.icon}
            />
            <Text 
                align='center'
                variant='body1'
                className={classes.facility}
            > {facility} </Text>
        </Grid>

    )
}
export default OfferList