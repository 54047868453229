import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
    heading:{
        color:`${theme.palette.secondary.main} !important`,
        fontWeight: theme.typography.fontWeightBold,
        paddingBottom: theme.palette.spacing,
        marginBottom: theme.palette.spacing*2,
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.palette.spacing*2
        }
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
    }
}))
export default useStyles