import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  playButton: {
    width: 70,
    height: 70,
    "& svg": {
      fill: "#000000de",
    },
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%",
    width: "100%",
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
  },
}));

export default useStyles;
