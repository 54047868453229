import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import {firestore} from '../../Helpers/Firebase'


export const getJob =  createAsyncThunk ('jobSlice/getJob', async()=>{
    try {
        const data = []
        const contents = await firestore.collection('job').get()
        contents.docs.forEach(doc =>{
            const currentID = doc.id
             // eslint-disable-next-line
            const appObj = { ...doc.data(), ['jobId']: currentID }
            data.push(appObj)
        }) 
        return data
    } catch (error) {
        throw Error(error);
    }

})
export const getSingleJob =  createAsyncThunk ('getSingleJob/getJob', async(id='CMxgnBuvOPzVPeYdq4wT')=>{
    try {
        const contents = await firestore.collection('job').doc(id).get()
        return contents.data()
    } catch (error) {
        throw Error(error);
    }

})
export const createJob =  createAsyncThunk ('jobSlice/createJob', async(data)=>{
    try {
        await firestore.collection("job").add(data);
        
    } catch (error) {
        throw Error(error);
    }

})


export const jobSlice = createSlice({
    name: "jobSlice",
    initialState: {
        loading: true,
        jobContent: [],
        singleJob:{}
    },
    reducers: {},

    extraReducers:{
        [getJob.pending]: (state, action) => {
            state.loading = true
            
        },
        [getJob.fulfilled]: (state, action) => {
            state.loading = false
            state.jobContent = action.payload
        },
        [createJob.pending]: (state) => {
            state.loading = true
            
        },
        [createJob.pending]: (state) => {
            state.loading = false
            
        },
        [getSingleJob.pending]: (state) => {
            state.loading = true
            
        },
        [getSingleJob.fulfilled]: (state, action) => {
            state.loading = false
            state.singleJob = action.payload
            
        },
    }
});

export const jobSliceSelector = (state) => state.entities.jobSlice
export default jobSlice.reducer;