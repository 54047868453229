import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
    container : {
        marginTop: -theme.palette.spacing * 6,
        backgroundColor: theme.palette.common.white,
        boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.06)',
        marginBottom: theme.palette.spacing * 4,
        borderRadius: 4,
    },
}))


export default useStyles