import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  messageBox: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
  titleBold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  titleColor: {
    color: `${theme.palette.secondary.main} !important`,
    // fontWeight: theme.typography.fontWeightMedium,
  },
}));

export const content = [
  {
    name: "MzTech",
    designation: "Founder & CEO",
    message:
      "I want to take this moment to acknowledge and thank you, our valued clients, and partners, for your continuous support and trust. Your confidence in us is humbling and this makes me redouble my commitment and belief in our core values of Simplicity, Honesty, and Humility.",
    imageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1621389054/MzTechCorp/MzTech_logo_with_square_background_zslpjs.png",
  },
  {
    name: "MzTech",
    designation: "Administrator",
    message:
      "As a leader in the renovation, caring about our team members’ health and well-being by providing a safe work environment and encouraging a healthy lifestyle. Above all, we aim to be fair and honest with team members and provide meaningful, challenging work to tackle each day.",
    imageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1621389054/MzTechCorp/MzTech_logo_with_square_background_zslpjs.png",
  },
];
