import React from 'react'
import {Box,makeStyles} from '@material-ui/core'

const useStyle = makeStyles(theme =>({
    middleBorder:{
        height:250,
        
        borderRight:`1px solid ${theme.palette.common.primaryHover}`,
        margin:5,
        [theme.breakpoints.down('sm')]:{
            height:1,
            width:300,
            borderBottom:`1px solid ${theme.palette.common.primaryHover}`,
        }
    },
    sideBorder:{
        height:200,
        borderRight:`1px solid ${theme.palette.common.secondaryHover}`,
        [theme.breakpoints.down('sm')]:{
            height:1,
            width:250,
            borderBottom:`1px solid ${theme.palette.common.secondaryHover}`,
        }
    },

    container:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'row',
        [theme.breakpoints.down('sm')]:{
            flexDirection:'column',
            margin:"25px 0"
        }
    }

}))

const MessageDivider = () => {
    const style = useStyle()
    return (
        <Box className={style.container}>
            <Box className={style.sideBorder}></Box>
            <Box className={style.middleBorder} ></Box>
            <Box className={style.sideBorder}></Box>
            
        </Box>
    )
}

export default MessageDivider
