import { makeStyles } from '@material-ui/core/styles'


const useStyle = makeStyles(theme => ({
    'paragraph_default': {
        fontSize: '16px',
        color: theme.palette.secondary.main,
        fontFamily: 'Roboto',
        lineHeight: '1.19',
        letterSpacing: '0.38px'
    }
}))


export default useStyle