import { Container } from "@material-ui/core";
import React from "react";
import { Text, Section } from "./../../../Components";
import GetInTouchCss from "./GetInTouchCss";

const GetInTouch = () => {
  const contents = {
    sectionTitle: "Get In Touch",
    sectionAbout:
      "If you’re seeking an acknowledged web development company, then you are in the right place. Please let us know your concern or scope of the work and we will get back to you shortly.",
  };
  const style = GetInTouchCss();
  return (
    <>
      <Section title={contents.sectionTitle} />

      <div className={style.sectionAbout}>
        <Container maxWidth="md" justify="center">
          <Text className={style.sectionAboutText} variant="body1">
            {contents.sectionAbout}
          </Text>
        </Container>
      </div>
    </>
  );
};

export default GetInTouch;
