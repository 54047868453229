import React, { useEffect } from "react";
import smoothScrollTop from "../../Helpers/smoothScrollTop";
import { contents, useStyle } from "./Utility";
import Header from "./Header";
import { Box, Container, Grid, Fade } from "@material-ui/core";
import Text from "../../Components/text/Text";
import useFetch from "../../Helpers/customHooks/useFetch";
import calculateSpacing from "../CareerPage/calculateSpacing";
import { Helmet } from "react-helmet";
import VisibilityIcon from "@material-ui/icons/Visibility";

const Portfolio = ({ width }) => {
  const pageContent = useFetch();

  useEffect(() => {
    smoothScrollTop();
  }, []);

  const classes = useStyle();

  const delay = (index) => index * 70 + 100;

  return (
    <>
      <Helmet>
        <title>MzTech - Our Work</title>
        <meta
          name="description"
          content="We support IT service industries across North America. Our marketplace includes thousands of qualified service providers with a range of skillsets ready to help you get work done."
        />
      </Helmet>
      <Header contents={contents.header} />
      <Box className={classes.sectionResponsive}>
        <Container>
          <Grid container spacing={calculateSpacing(width)} align="center">
            {pageContent?.Portfolio &&
              Object.entries(pageContent?.Portfolio).map(
                ([key, item], index) => (
                  <Fade in {...{ timeout: delay(index) }}>
                    <Grid item xs={12} sm={6} md={3} key={key}>
                      <>
                        <img
                          className={classes.imageContainer}
                          src={item?.squareImageUrl}
                          alt={item?.title}
                        />

                        <Text
                          className={classes.projectLink}
                          variant="h5"
                          onClick={() => window.open(`${item?.link}`, "_blank")}
                        >
                          <VisibilityIcon />
                          View Project
                        </Text>
                      </>
                    </Grid>
                  </Fade>
                )
              )}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Portfolio;
