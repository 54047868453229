import React from "react";
import { motion } from "framer-motion";
import { Grid, IconButton } from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import { Text } from "./../";
import style from "./style";

const item = {
  hidden: { y: 10, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const TeamMemberCard = ({ name, designation, imageUrl, socialLinks, id }) => {
  const classes = style();
  return (
    <Grid key={id} item xs={6} sm={4} md={3} className={classes.grid}>
      <motion.div
        variants={item}
        transition={{ duration: 0.5 }}
        className={classes.cardContainer}
      >
        <div className={classes.imageContainer}>
          <img src={imageUrl} className={classes.image} alt={id} />
        </div>

        {socialLinks && (
          <div className={classes.iconContainer}>
            <div className={classes.icons}>
              {socialLinks?.linkedin && (
                <IconButton
                  className={classes.icon}
                  onClick={() => window.open(socialLinks?.linkedin, "_blank")}
                >
                  <LinkedInIcon />
                </IconButton>
              )}
              {socialLinks?.twitter && (
                <IconButton
                  className={classes.icon}
                  onClick={() => window.open(socialLinks?.twitter, "_blank")}
                >
                  <TwitterIcon />
                </IconButton>
              )}
              {socialLinks?.facebook && (
                <IconButton
                  className={classes.icon}
                  onClick={() => window.open(socialLinks?.facebook, "_blank")}
                >
                  <FacebookIcon />
                </IconButton>
              )}
            </div>
          </div>
        )}

        <Text
          align="center"
          variant="h5"
          className={`${classes.nameText} ${!socialLinks} && ${
            classes.extraSpacing
          }`}
        >
          {name}
        </Text>
        <Text align="center" className={classes.designationText}>
          {designation}
        </Text>
      </motion.div>
    </Grid>
  );
};

export default TeamMemberCard;
