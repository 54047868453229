import React from "react";
import { Box } from "@material-ui/core";
import BodyCard from "../../Components/aboutUsPage/BodyCard";
import Text from "../../Components/text/Text";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  sectionResponsive: {
    marginTop: theme.spacing(8),
  },
  title: {
    backgroundColor: theme.palette.white.main,
    height: theme.spacing(8),
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  titleBold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  summary: {
    marginBottom: theme.spacing(4),
    fontSize: "1.3rem !important",
    fontWeight: 200,
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: theme.palette.white.main,
  },
  innerContainer: {
    width: 570,
    height: "auto",
    MaxHeight: 394,
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      width: 400,
      MaxHeight: 350,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      MaxHeight: 300,
    },
  },
}));

const list = [
  {
    title: "Who We Are",
    text: "MzTech is a team of accomplished engineers and tech professionals who are committed to utilizing state-of-the-art resources to help clients acquire their digital aspirations. Our services encompass a host of development procedures to meet your needs today - all in order to open doors for tomorrow in the wide world of technology.",
    image:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1620291198/MzTubeWeb/annie-spratt-hCb3lIB8L8E-unsplash_eniy1x.jpg",
  },
  {
    title: "What We Do",
    text: "Our software development leaders consult with companies hand-in-hand to ensure their goals and our focus are targeted and match professionals and companies to implement meaningful projects that make our clients’ digital platforms accessible, responsive and dynamic - all in order to instill creativity and innovation in the field of computers and information technology. Together, we have enabled programmer-informed change in over 150 companies.",
    image:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1620291849/MzTubeWeb/campaign-creators-gMsnXqILjp4-unsplash_kwazvm.jpg",
  },
  // {
  //   title: "Our Approach",
  //   text: "To help our clients make distinctive, lasting, and substantial improvements in their performance and to build a great firm that attracts, develops, excites, and retains exceptional people.",
  //   image:
  //     "https://res.cloudinary.com/dv244dkbr/image/upload/v1620291198/MzTubeWeb/annie-spratt-hCb3lIB8L8E-unsplash_eniy1x.jpg",
  // },
];

const Card = () => {
  const style = useStyle();
  return (
    <Box className={style.sectionResponsive}>
      {list.map((item, index) => {
        return (
          <Box key={index} px={2}>
            <Box className={style.title}>
              <Text variant="h2" align="center">
                {item.title}
              </Text>
            </Box>
            <Box className={style.container}>
              <BodyCard
                src={item.image}
                direction={index % 2 === 0 ? "row" : "row-reverse"}
                className={style.innerContainer}
              >
                <Text variant="h4" className={style.summary}>
                  {item.text}
                </Text>
              </BodyCard>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default Card;
