import { makeStyles } from "@material-ui/core";
import {Text} from './../../Components'

const useStyles = makeStyles((theme) => ({
    sectionDescription: {
        marginTop: theme.palette.spacing*3,
    }
}));

const Description = (props) => {
    const classes = useStyles(props);
    const {description, id} = props
    return (
        <Text
            variant='body1'
            align='left'
            key={id}
            className={classes.sectionDescription}
        >
            {description?.list ? 
                description?.list.map(element => (
                    <li> {element} </li>
                ))
                :
                description
            }
        </Text>
    )
}
export default Description