import firebase from "firebase"


const firebaseConfig = {
  apiKey: "AIzaSyBm4mnd4sji3wqCREqjzjDZ35ahsQ7SSBM",
  authDomain: "new-mztech.firebaseapp.com",
  projectId: "new-mztech",
  storageBucket: "new-mztech.appspot.com",
  messagingSenderId: "865539917861",
  appId: "1:865539917861:web:4e757b0094207ce7a157cc",
  measurementId: "G-M8YEW41W9F",
};


const app = firebase.initializeApp(firebaseConfig);
const firestore = app.firestore();
const db = app.database();
const auth = app.auth();
const functions = app.functions();

export {firestore,db,auth,functions};

