import React, { useEffect } from "react";
import { Box, Container } from "@material-ui/core";
import Button from "../Button";
import { Text, TypeWriterEffect } from "..";
import AOS from "aos";
import "aos/dist/aos.css";
import { ThemeProvider } from "@material-ui/core/styles";
import style from "./style";
import theme from "../../theme";
import { Link } from "react-router-dom";

function ImageOverlay(props) {
  const {
    title,
    subTitle,
    buttonOneText,
    buttonOneLink,
    buttonTwoText,
    buttonTwoLink,
    btnBackgroundColor,
    typed,
  } = props;
  const classes = style(props);
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.heroContainer}>
        <Box className={classes.overlayEffect}>
          <Container maxWidth="lg">
            <Box className={classes.heroContent}>
              <Text
                data-aos="fade-right"
                variant={typed ? "h3" : "h2"}
                className={classes.title}
              >
                {title}
              </Text>
              {typed && <TypeWriterEffect color={theme.palette.common.black} />}
              <Text
                data-aos="fade-left"
                variant="h3"
                className={classes.subTitle}
              >
                {subTitle}
              </Text>
              {buttonOneText && (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    backgroundColor={btnBackgroundColor}
                    className={classes.btn}
                  >
                    <Link to={buttonOneLink} style={{ color: "white" }}>
                      {buttonOneText}
                    </Link>
                  </Button>
                </>
              )}
              {buttonTwoText && (
                <>
                  <Button
                    variant="outlined"
                    textColor="black"
                    borderColor="black"
                    backgroundColor={"transparent"}
                    className={classes.btn}
                    href={buttonTwoLink}
                  >
                    <Link to={buttonTwoLink} style={{ color: "black" }}>
                      {buttonTwoText}
                    </Link>
                  </Button>
                </>
              )}
            </Box>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default ImageOverlay;
