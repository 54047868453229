import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import smoothScrollTop from "../../Helpers/smoothScrollTop";
import { Form } from "./../../Components";
import GetInTouch from "./GetInTouch";
import Header from "./Header";

const ContactUsPage = () => {
  useEffect(() => {
    smoothScrollTop();
  }, []);

  return (
    <>
      <Helmet>
        <title>MzTech - Contact Us</title>
      </Helmet>
      <Header />
      <GetInTouch />
      <Form />
    </>
  );
};

export default ContactUsPage;
