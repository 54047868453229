import React from 'react'
import Carousel from 'react-material-ui-carousel'

const CustomCarousel = ({children,...rest}) => {
    return (
        <Carousel navButtonsAlwaysVisible={true}  {...rest}
        navButtonsProps={{
            style: {
                backgroundColor: '#ffffff',
                borderRadius: 50,
                color:'#02334D',
                width:40,
                height:40,
                boxShadow:`0 0 3px`,
                transition:'linear .2s'
            
            }
        }}
        >
        {children}
        </Carousel>
    )
}

export default CustomCarousel
