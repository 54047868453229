import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  header_wrapper: {
    background: (props) =>
      props.scroll ? "#062639" : theme.palette.white.main,
    height: 56,
    [theme.breakpoints.down("sm")]: {
      height: 52,
    },
    transition: "all 0.7s ease-out",
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 200,
    boxShadow: (props) => !props.scroll && "0 1px 2px 0 rgba(0, 0, 0, 0.1)",
  },
  hambergurMenu: {
    fill: (props) => props.scroll && theme.palette.white.main,
  },

  list: {
    width: 250,
  },
  linkColor: {
    color: theme.palette.common.textColor,
  },
  navbar: {
    margin: 0,
    padding: 0,
    display: "flex",
    listStyle: "none",
    "& li": {
      margin: theme.spacing(2),
      cursor: "pointer",
      transition: "0.3s",

      "& a": {
        fontSize: "16px",
        color: (props) =>
          props.scroll
            ? theme.palette.white.main
            : theme.palette.common.textColor,
        "&:hover": {
          color: theme.palette.common.secondaryHover,
          transition: "all 0.1s ease-out",
        },
      },
    },
  },

  logo: {
    "& svg": {
      height: 40,
      width: "auto",
      display: "block",
    },
  },
}));

export default useStyle;
