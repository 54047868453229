import { Container, makeStyles, Box } from "@material-ui/core";
import React, { useEffect, lazy } from "react";
import { Link } from "react-router-dom";

import companyLogos from "../../Components/LogosSlider/logosData";
import { whyMzTech } from "./homePageContents";
import useFetchHome from "./../../Helpers/customHooks/useFetchHome";
import useFetch from "./../../Helpers/customHooks/useFetch";
import Section from "../../Components/Section/index";
import smoothScrollTop from "../../Helpers/smoothScrollTop";
import Header from "./Header";
import { Helmet } from "react-helmet";
import ServicesGrid from "../Services/ServicesGrid";
import Carousel from "./carousel/Carousel";
import Portfolio from "./carousel/Portfolio";
import { Text } from "../../Components";
const WhyMzTechSection = lazy(() => import("./WhyMzTechSection"));
// const CountSection = lazy(() => import("../aboutUs/CountSection"));
const LogosSlider = lazy(() => import("../../Components/LogosSlider"));

const useStyles = makeStyles((theme) => ({
  servicesLink: {
    "& h5": {
      color: `${theme.palette.secondary.main} !important`,
      paddingTop: theme.palette.spacing * 1.5,
    },
    "& h5:hover": { fontWeight: theme.typography.fontWeightBold },
  },

  clientWrapper: {
    backgroundColor: theme.palette.common.white,
  },
}));

const HomePage = () => {
  const classes = useStyles();
  const homePageContent = useFetchHome();
  const pageContent = useFetch();

  useEffect(() => {
    smoothScrollTop();
  }, []);

  const returnSubsetOfServices = (dataToFilter) =>
    Object.fromEntries(Object.entries(dataToFilter).filter(([key]) => key < 6));

  return (
    <>
      <Helmet>
        <title>MzTech - Software Development Corporation</title>
      </Helmet>
      <Header />

      <WhyMzTechSection contents={whyMzTech} />
      <Container maxWidth="md">
        <Section title="Our Services" />
        {pageContent?.Services && (
          <ServicesGrid
            services={returnSubsetOfServices(pageContent.Services)}
          />
        )}
        <Link key="services" to="/services" className={classes.servicesLink}>
          <Text variant="h5" align="center">
            View All Services
          </Text>
        </Link>
      </Container>
      {/* <CountSection /> */}
      {companyLogos.length > 0 && (
        <Box className={classes.clientWrapper}>
          <Container maxWidth="md">
            <Section title="Our Clients" />
            {companyLogos[0].length > 2 && companyLogos[0] && (
              <LogosSlider content={companyLogos[0]} />
            )}
            {companyLogos[1].length > 2 && companyLogos[1] && (
              <LogosSlider content={companyLogos[1]} rtl />
            )}
          </Container>
        </Box>
      )}
      <Portfolio content={pageContent?.Portfolio} />
      {homePageContent?.customerReview && (
        <Carousel content={homePageContent?.customerReview} />
      )}
    </>
  );
};

export default HomePage;
