import React from "react";
import ImageOverlay from "../../Components/ImageOverlay";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../../theme";
import career from './../../images/img2.jpg'


const content = {
  imageUrl:
    "https://res.cloudinary.com/dv244dkbr/image/upload/v1621562057/MzTechCorp/careerContent/overlay_career_rlwx8b.png",
  title: "Career",
  subTitle:
    "It's an exciting and important time to be a part of MzTech corp. We're strong, innovative, passtionate and we want you to be part our success stories.",
};

const Header = () => {
  return (
    <ThemeProvider theme={theme}>
      <ImageOverlay
        image={career}
        title={content.title}
        subTitle={content.subTitle}
        bgColor={theme.palette.common.imageOverlayBlue}
      />
    </ThemeProvider>
  );
};

export default Header;
