import { makeStyles } from "@material-ui/core/styles";

export const useStyle = makeStyles((theme) => ({
  sectionResponsive: {
    marginTop: theme.spacing(8),
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(15),
    },
  },
  height: {
    height: 350,
    [theme.breakpoints.down("md")]: {
      height: 300,
    },
  },
  title: {
    backgroundColor: theme.palette.white.main,
    height: theme.spacing(8),
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  titleBold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  titleColor: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.secondary.main,
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  innerContainer: {
    margin: "0 auto",
    width: "100%",
    height: 450,
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      height: 400,
    },
    [theme.breakpoints.down("xs")]: {
      height: 250,
    },
  },
  cursor: {
    cursor: "pointer",
  },

  wrapper: {
    backgroundColor: theme.palette.common.white,
  },
  body: {
    backgroundColor: theme.palette.common.greyishBackground,
  },
  imageContainer: {
    width: 300,
    height: "250px !important",
    border: "0.1px solid whitesmoke",
    objectFit: "cover",
    overflow: "hidden",
    borderRadius: 15,
    margin: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  customerImage: {
    width: 100,
    height: 100,
    objectFit: "cover",
    borderRadius: "50%",
  },

  projectLink: {
    marginTop: -15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "capitalize !important",
    cursor: "pointer",
    color: `${theme.palette.common.secondaryHover} !important`,
    "&:hover": { fontWeight: theme.typography.fontWeightMedium },
    "& svg": { margin: 5, fontSize: 17 },
  },
}));

export const contents = {
  header: {
    imageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1621937745/MzTechCorp/services/banners/services-page-banner_sxetaj.png",
    title: "Work we are proud of",
    subTitle:
      "Our spectrum of software and app development procedures includes web design, web migration, search engine optimization, web speed optimization, e-commerce and digital marketing.",
  },
  portfolioInfo: [
    {
      title: "MzTube",
      discripation:
        "With a large portfolio of industry leading mobile application development services, we know how to translate our solutions into the result of your business growth",
      imageUrl:
        "https://res.cloudinary.com/dv244dkbr/image/upload/v1621247233/MzTechCorp/portfolio%20pic/mobile_gdbdal.png",
      link: "#",
      linkText: "Visit App",
    },
    {
      title: "MzBnB for Bangladesh",
      discripation:
        "With a large portfolio of industry leading mobile application development services, we know how to translate our solutions into the result of your business growth",
      imageUrl:
        "https://res.cloudinary.com/dv244dkbr/image/upload/v1621247233/MzTechCorp/portfolio%20pic/screen_m06ezi.png",
      link: "#",
      linkText: "Visit website",
    },
    {
      title: "MzEcom",
      discripation:
        "With a large portfolio of industry leading mobile application development services, we know how to translate our solutions into the result of your business growth",
      imageUrl:
        "https://res.cloudinary.com/dv244dkbr/image/upload/v1621248805/MzTechCorp/portfolio%20pic/screen_vgnfnf.png",
      link: "https://ecom-mzdev.netlify.app/",
      linkText: "Visit website",
    },
    {
      title: "Fitness Website",
      discripation:
        "With a large portfolio of industry leading mobile application development services, we know how to translate our solutions into the result of your business growth",
      imageUrl:
        "https://res.cloudinary.com/dv244dkbr/image/upload/v1621247233/MzTechCorp/portfolio%20pic/screen_m06ezi.png",
      link: "#",
      linkText: "Visit website",
    },
  ],
};
