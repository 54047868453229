import { Text, CustomSlider } from "../../../Components";
import { Container } from "@material-ui/core";
import style from "./style";

const ServiceDescription = (props) => {
  const { content, colors } = props;
  const classes = style(props);
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className={classes.imageContainer}>
      <div className={classes.sectionContainer}>
        <div className={classes.titleContaier}>
          <Text className={classes.title} variant="h3">
            {content?.title}
          </Text>
        </div>
        <div className={classes.description}>
          <Text variant="body1" className={classes.body}>
            {content?.description}
          </Text>
        </div>
      </div>

      {content?.slider ? (
        <Container maxWidth="md" className={classes.container}>
          <CustomSlider
            moreSetting={settings}
            bgColor={colors?.backgroundColor}
            color={colors?.color}
          >
            {content?.imageUrl?.map((item, index) => (
              <img
                src={item}
                className={classes.sliderImage}
                alt={content?.title}
                key={index}
              />
            ))}
          </CustomSlider>
        </Container>
      ) : (
        <img
          src={content?.imageUrl}
          className={classes.image}
          alt={content?.title}
        />
      )}
      <div
        className={content?.slider ? classes.extraSlider : classes.extra2}
        style={{
          backgroundColor: colors?.backgroundColor,
        }}
      ></div>
    </div>
  );
};
export default ServiceDescription;
