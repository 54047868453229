import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  contactInfoHeading: {
    color: `${theme.palette.common.white} !important`,
    padding: theme.palette.spacing,
    marginBottom: theme.palette.spacing * 5,
  },
  icons: {
    marginTop: theme.palette.spacing * 1.5,
    cursor: "pointer",
  },
  icon: {
    color: theme.palette.common.white,
    marginLeft: theme.palette.spacing,
    marginRight: theme.palette.spacing,
  },
  contactInfo: {
    backgroundColor: theme.palette.common.primaryHover,
  },
  googleMap: {
    marginTop: theme.palette.spacing * 5,
    padding: theme.palette.spacing,
  },
}));

export default useStyles;
