import style from "./style";
import { Text } from "../../../Components";
import Offer from "./Offer";
import { Container, Grid } from "@material-ui/core";
const OfferList = ({ content, colors }) => {
  const classes = style();
  return (
    <div className={classes.container}>
      <Text variant="h2" className={classes.title}>
        {content?.title}
      </Text>

      {/* Offer List */}
      <Container maxWidth="lg">
        <Grid container justify="center">
          {content?.offerings &&
            content?.offerings.map(({ icon, facility }, id) => (
              <Offer
                icon={icon}
                facility={facility}
                key={id}
                backgroundColor={colors ? colors.backgroundColor : "#02334D"}
              />
            ))}
        </Grid>
      </Container>
    </div>
  );
};
export default OfferList;
