import { createMuiTheme } from "@material-ui/core";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

// colors
const primary = "#3F90BB";
const primaryHover = "#02334D";
const glance = "rgb(57,66,105)";
const secondary = "#B3294E";
const secondaryHover = "#7D1C36 ";
const backgroundLight = "#F9F9F9 ";
const backgroundDark = "#EAF2F5 ";
const warning = "#DB4A39 ";
const imageOverlay = "rgba(0,0,0,.6)";
const imageOverlayRed = "rgba(179, 41, 78, 0.7)";
const imageOverlayBlue = "rgba(2, 51, 77, 0.7)";
const textColor = "#707070";
const white = "#fff";
const greyishBackground = "rgba(0, 0, 0, 0.03)";
const errorTextColor = "#f44336";
const grey = "#757575";
const successTextColor = "#0BAB00";
const warmGrey = "#707070";
const transparent = "rgba(0,0,0,0)";
const fileUploaderBackground = "rgba(72, 72, 74, 0.05)";
const careerPageBackground = "rgba(0, 0, 0, 0.12)";
//socil icon color
const googlePlus = "#DB4A39";
const twitterColor = "#00ACEE";
const fbColor = "#4267B2";
const linkedin = "#0077b5";

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

const theme = createMuiTheme({
  palette: {
    primary: { main: primary },
    secondary: { main: secondary },
    background: { default: backgroundLight },
    warning: { main: warning },
    common: {
      primaryHover,
      secondaryHover,
      backgroundDark,
      glance,
      imageOverlay,
      imageOverlayRed,
      imageOverlayBlue,
      textColor,
      greyishBackground,
      errorTextColor,
      grey,
      successTextColor,
      warmGrey,
      transparent,
      fileUploaderBackground,
      careerPageBackground,
    },
    socilIconColor: {
      googlePlus,
      fbColor,
      twitterColor,
      linkedin,
    },
    white: { main: white },
    spacing,
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xl,
      lg,
      md,
      sm,
      xs,
    },
  },
  typography: {
    fontFamily: "Roboto",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: "2.5rem",
      [breakpoints.down("md")]: {
        fontSize: "2.188rem",
      },

      [breakpoints.down("sm")]: {
        fontSize: "1.563rem",
      },
    },
    h2: {
      fontSize: "2.188rem",
      [breakpoints.down("md")]: {
        fontSize: "1.563rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "1.25rem",
      },
    },
    h3: {
      fontSize: "1.25rem",
      [breakpoints.down("sm")]: {
        fontSize: "1.125rem",
      },
    },
    h4: {
      fontSize: "1.5rem",
      [breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
    },
    h5: {
      fontSize: "1rem",
      [breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
    },
    body1: {
      fontSize: "1rem",
      [breakpoints.down("sm")]: {
        fontSize: "0.913rem",
      },
    },
    button: {
      fontSize: "1rem",
      [breakpoints.down("md")]: {
        fontSize: "0.875rem",
      },
    },
  },

  fonts: {
    font10: `10px`,
    font11: `11px`,
    font12: `12px`,
    font13: `13px`,
    font14: `14px`,
    font15: `15px`,
    font16: `16px`,
    font18: `18px`,
    font20: `20px`,
    font25: `25px`,
    font30: `30px`,
    font31: `31px`,
    font32: `32px`,
    font33: `33px`,
    font35: `35px`,
    font40: "40px",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*": {
          margin: "0",
          padding: "0",
        },
        a: {
          textDecoration: "none !important",
        },
      },
    },
  },
});

export default theme;
