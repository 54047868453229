const companyLogos = [
  [
    "https://res.cloudinary.com/dv244dkbr/image/upload/v1621308863/MzTechCorp/clientLogos/bank-of-scotland_tykh8o.svg",
    "https://res.cloudinary.com/dv244dkbr/image/upload/v1621307600/MzTechCorp/clientLogos/brand-rex_nhpd8n.svg",
    "https://res.cloudinary.com/dv244dkbr/image/upload/v1621309519/MzTechCorp/clientLogos/interface_pnezuy.svg",
    "https://res.cloudinary.com/dv244dkbr/image/upload/v1621307599/MzTechCorp/clientLogos/keukenhof_mlhwlr.svg",
    "https://res.cloudinary.com/dv244dkbr/image/upload/v1621307598/MzTechCorp/clientLogos/informatika_fqq21j.svg",
    "https://res.cloudinary.com/dv244dkbr/image/upload/v1621307598/MzTechCorp/clientLogos/condor-earth-technologies_xurmr9.svg",
    "https://res.cloudinary.com/dv244dkbr/image/upload/v1621307598/MzTechCorp/clientLogos/KTM-logo_cw20sg.svg",
  ],
  [
    "https://res.cloudinary.com/dv244dkbr/image/upload/v1621307601/MzTechCorp/clientLogos/jackson_qrj7vf.svg",
    "https://res.cloudinary.com/dv244dkbr/image/upload/v1621307596/MzTechCorp/clientLogos/agroekonomik_mzcxqp.svg",
    "https://res.cloudinary.com/dv244dkbr/image/upload/v1621307596/MzTechCorp/clientLogos/milliyet_hwndj5.svg",
    "https://res.cloudinary.com/dv244dkbr/image/upload/v1621307596/MzTechCorp/clientLogos/baltika-4_tdsely.svg",
    "https://res.cloudinary.com/dv244dkbr/image/upload/v1621307596/MzTechCorp/clientLogos/backplane-40067_lchesv.svg",
    "https://res.cloudinary.com/dv244dkbr/image/upload/v1621307596/MzTechCorp/clientLogos/technicolor-sound_exfjoh.svg",
  ],
];

export default companyLogos;
