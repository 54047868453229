import React from "react";
import style from "./style";
import { Grid, Box } from "@material-ui/core";
import IconWithText from "./../IconWithText";
import { Text } from "./../../";
import useFetch from "./../../../Helpers/customHooks/useFetch";
import { contactInfo, socialInfo, phoneTransform } from "../../CommonData";

// Icons
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";

const ContactInfo = (props) => {
  const classes = style(props);
  // eslint-disable-next-line no-unused-vars
  const pageContent = useFetch("Contact Us");

  const { address, number, email } = contactInfo;

  return (
    <Box component={Grid} className={classes.contactInfo} item md={5} xs={12}>
      <Grid container>
        <Grid item xs={12}>
          <Text
            variant="h4"
            className={classes.contactInfoHeading}
            align="left"
          >
            {" "}
            Contact Info{" "}
          </Text>
        </Grid>
        <Grid item xs={12}>
          <IconWithText title={address}>
            <LocationOnIcon className={classes.icon} />
          </IconWithText>
        </Grid>

        <Grid item xs={12}>
          <a href={`tel:${phoneTransform()}`}>
            <IconWithText title={number}>
              <PhoneIcon className={classes.icon} />
            </IconWithText>
          </a>
        </Grid>

        <Grid item xs={12}>
          <a href={`mailto:${email}`}>
            <IconWithText title={email}>
              <EmailIcon className={classes.icon} />
            </IconWithText>
          </a>
        </Grid>

        <Grid item xs={12} className={classes.icons}>
          <FacebookIcon
            className={classes.icon}
            onClick={() => window.open(`${socialInfo[0].link}`, "_blank")}
          />
          <LinkedInIcon
            className={classes.icon}
            onClick={() => window.open(`${socialInfo[1].link}`, "_blank")}
          />
          <TwitterIcon
            className={classes.icon}
            onClick={() => window.open(`${socialInfo[2].link}`, "_blank")}
          />
        </Grid>

        <Grid item xs={12} className={classes.googleMap}>
          <iframe
            title={"mztech"}
            width="100%"
            height="200"
            allowfullscreen=""
            loading="lazy"
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBjREWhsKyoicFvxV-ur5DYreBxUTRhtC0
    &q=Harrison+St,+New+York,+NY+10013"
          ></iframe>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ContactInfo;
