import React from "react";
import { Box, Grid, Container } from "@material-ui/core";
import { ReactSVG } from "react-svg";
import Logo from "../../../images/logoFull.svg";
import { Text } from "../../../Components";
import {
  footerSectionOne,
  footerSectionTwo,
  footerSectionThree,
  footerSectionFour,
  footerSectionFive,
  useStyle,
} from "./Utility";
import CopyrightIcon from "@material-ui/icons/Copyright";
import { Link } from "react-router-dom";
import useFetch from '../../../Helpers/customHooks/useFetch'

const Footer = () => {
  const style = useStyle();
  const pageContent = useFetch();

  return (
    <Box py={3} mx="auto">
      <Container>
        <Grid container direction="column">
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Box>
                <Link to="/">
                  <ReactSVG className={style.logo} src={Logo} alt="MzTech" />
                </Link>
              </Box>
              <Text variant="body2">{footerSectionOne.text}</Text>
              <Box display="flex" alignItems="center" my={2}>
                <Box
                  mr={1}
                  onClick={() =>
                    window.open(footerSectionOne.faceBook.url, "_blank")
                  }
                >
                  <footerSectionOne.faceBook.icon
                    fontSize="large"
                    className={style.faceBookColor}
                  />
                </Box>
                <Box
                  onClick={() =>
                    window.open(footerSectionOne.linkedIn.url, "_blank")
                  }
                >
                  <footerSectionOne.linkedIn.icon
                    fontSize="large"
                    className={style.linkedInColor}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box>
                <Box>
                  <Text variant="h3" className={style.titleBold}>
                    {footerSectionTwo.title}
                  </Text>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  className={style.link}
                  mt={1}
                >
                  {pageContent?.Services && Object.entries(pageContent?.Services).map(([index, service]) => (
                    <Link 
                      key={index} 
                      to={`/services${service.link}`}
                    >
                        {service.title} 
                    </Link>
                    )
                  )}

                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box>
                <Text variant="h3" className={style.titleBold}>
                  {footerSectionThree.title}
                </Text>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                className={style.link}
                mt={1}
              >
                {footerSectionThree.serviecs.map((item, index) => {
                  return (
                    <Link 
                      key={index} 
                      to={item.link}
                    >
                        {item.text} 
                    </Link>
                  );
                })}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box>
                <Text variant="h3" className={style.titleBold}>
                  {footerSectionFour.title}
                </Text>
              </Box>
              <Box mt={1}>
                {footerSectionFour.info.map((item, index) => {
                  return (
                    <Box
                      my={1}
                      key={index}
                      display="flex"
                      alignItems="flex-start"
                    >
                      <Box>{item.icon}</Box>
                      <Box ml={1}>
                        <Text variant="body2" className={style.color}>
                          {item.text}
                        </Text>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              mt={6}
            >
              <Box className={style.underline} mb={3}></Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
              >
                <Box mr="5px">
                  <CopyrightIcon fontSize="small" />
                </Box>
                <Text variant="h3" className={style.titleBold}>
                  {footerSectionFive.bottonText} {new Date().getFullYear()}
                </Text>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
