import React from 'react';
import Dropzone from 'react-dropzone';
// import { Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CloudUpload from '@material-ui/icons/CloudUpload';
// import { FormHelperText } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fafafa',
    cursor: 'pointer',
    color: '#333',
    // padding: '10px',
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  icon: {
    marginTop: '10px',
    color: '#888888',
    fontSize: '42px',
  },
}));

export const FileDrop = ({onChange,value}) => {
  
  const styles = useStyles();

  return (
    <Dropzone onDrop={onChange}>
  {({getRootProps, getInputProps}) => (
    
      <Paper variant="outlined" className={styles.root} {...getRootProps()}>
        <input {...getInputProps()} />
        <CloudUpload />
        <p>{value[0]?.name ? value[0]?.name : "Drag 'n' drop some files here, or click to select files" }</p>
      </Paper>
    
  )}
</Dropzone>
  );
};
