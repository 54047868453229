import React from "react";
import { Box, Container } from "@material-ui/core";
import { useStyle } from "./Utility";
import { CustomCarousel, Text, Section } from "../../../Components";

const Carousel = ({ content }) => {
  const { title, reviews } = content;
  const style = useStyle();
  return (
    <>
      <Section title={title} />
      <Box py={4} className={style.body}>
        <Container maxWidth="md">
          <CustomCarousel>
            {reviews?.map(({ name, message, imageUrl, role }, index) => {
              return (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Box px={5}>
                    <Text variant="h5" align="left">
                      {message}
                    </Text>
                  </Box>
                  <Box my={1}>
                    <img
                      className={style.customerImage}
                      src={imageUrl}
                      alt="profile Pic"
                    />
                  </Box>
                  <Box>
                    <Text variant="h3">{name} </Text>
                  </Box>
                  <Box>
                    <Text variant="body1">{role}</Text>
                  </Box>
                </Box>
              );
            })}
          </CustomCarousel>
        </Container>
      </Box>
    </>
  );
};

export default Carousel;
