import { Grid } from "@material-ui/core";
import React from "react";
import { motion } from "framer-motion";
import CareerCard from "./CareerCard";
import calculateSpacing from "./calculateSpacing";

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const CareerGrid = ({ width, posts }) => {
  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="visible"
      className="container"
    >
      <Grid container spacing={calculateSpacing(width)}>
        {posts &&
          posts.map(({ image, title, summary, jobId }) => (
            
            <Grid item xs={12} sm={6} md={4} key={jobId}>
              <CareerCard
                imageUrl={image[0]?.imageUrl}
                title={title}
                summary={summary}
                link={`/career/${jobId}`}
              />
            </Grid>
          ))}
      </Grid>
    </motion.div>
  );
};

export default CareerGrid;
