import ReactPlayer from "react-player";
import React from "react";
import PlayIcon from "@material-ui/icons/PlayCircleFilled";
import style from "./style";

const VideoPlayer = ({ url, thumbnail }) => {
  const classes = style();
  return (
    <div className={classes.playerWrapper}>
      <ReactPlayer
        className={classes.reactPlayer}
        url={url}
        controls={true}
        playing
        width="100%"
        height="100%"
        playIcon={<PlayIcon className={classes.playButton} />}
        light={thumbnail}
      />
    </div>
  );
};
export default VideoPlayer;
