import style from './style'
import {Text} from '../..'

const IconWithText = ({children, title}) => {
    const classes = style()
     return(
        <>
            <div className={classes.iconWithText} >
                {children}
                <Text
                    variant='body1'
                    className={classes.textColor}
                > 
                    {title}
                </Text>
            </div>

        </>
    )
}
export default IconWithText;


