import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
    header:{
        display: 'flex',
        alignItems: 'center'
    },
    headerText:{
        fontWeight: theme.typography.fontWeightMedium,
        color: `${theme.palette.common.primaryHover} !important`,
    },
    icon:{
        marginLeft: theme.palette.spacing*2,
        marginRight: theme.palette.spacing*0.7,
        color: theme.palette.common.primaryHover
    },
    text:{
        color: `${theme.palette.common.primaryHover} !important`,
        marginLeft: theme.palette.spacing*2,
        marginBottom: theme.palette.spacing,
    },
}))
export default useStyles