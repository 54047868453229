import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  careerPageWrapper: {
    backgroundColor: theme.palette.common.greyishBackground,
  },
  root: {
    flexGrow: 1,
  },
  hero: {
    height: 400,
    [theme.breakpoints.down("md")]: {
      height: 300,
    },
  },
  titleBold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  titleColor: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.secondary.main,
  },
  seachInput: {
    width: "40%",
    padding: "5px 15px",
    borderRadius: theme.palette.spacing * 2,
    boxShadow: "0 3px 6px 0 rgb(0 0 0 / 16%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  iconButton: {
    padding: 10,
  },
  teamSection: {
    marginTop: theme.palette.spacing * 4,
    marginBottom: theme.palette.spacing * 4,
    backgroundColor: theme.palette.common.primaryHover,
  },
  teamContent: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.primaryHover,
    marginLeft: "calc(20vw)",
    paddingRight: "calc(20vw)",
    padding: theme.palette.spacing * 2,
    [theme.breakpoints.down("md")]: {
      marginLeft: "calc(10vw)",
      paddingRight: "calc(10vw)",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "calc(5vw)",
      paddingRight: "calc(5vw)",
    },
  },
  teamSubTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  teamTitle: {
    fontWeight: theme.typography.fontWeightBold,
    padding: "15px 0",
  },
  mzContent: {
    textAlign: "right",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.primaryHover,
    marginRight: "calc(20vw)",
    paddingLeft: "calc(20vw)",
    padding: theme.palette.spacing * 2,
    [theme.breakpoints.down("md")]: {
      marginRIght: "calc(10vw)",
      paddingLeft: "calc(10vw)",
    },
    [theme.breakpoints.down("sm")]: {
      marginRight: "calc(5vw)",
      paddingLeft: "calc(5vw)",
    },
  },
}));

export const careerContents = {
  benefits: {
    title: "Our Benefits",
    offerings: [
      {
        icon: "https://res.cloudinary.com/dv244dkbr/image/upload/v1621563746/MzTechCorp/icons/career_care_j7wjsu.svg",
        facility: "Exceptional Care",
      },
      {
        icon: "https://res.cloudinary.com/dv244dkbr/image/upload/v1621563744/MzTechCorp/icons/career_leaders_szzemn.svg",
        facility: "Supportive Leaders",
      },
      {
        icon: "https://res.cloudinary.com/dv244dkbr/image/upload/v1621563744/MzTechCorp/icons/career_comunity_f4vgks.svg",
        facility: "Strong Community",
      },
      {
        icon: "https://res.cloudinary.com/dv244dkbr/image/upload/v1621563744/MzTechCorp/icons/career_wellness_xfsbmr.svg",
        facility: "Health & Wellness",
      },
      {
        icon: "https://res.cloudinary.com/dv244dkbr/image/upload/v1621563744/MzTechCorp/icons/career_environment_vxyzvo.svg",
        facility: "Friendly Environment",
      },
      {
        icon: "https://res.cloudinary.com/dv244dkbr/image/upload/v1621563744/MzTechCorp/icons/career_prosperity_kzmmde.svg",
        facility: "Track towards prosperity",
      },
      {
        icon: "https://res.cloudinary.com/dv244dkbr/image/upload/v1621565998/MzTechCorp/icons/career_growth_i29y6u.svg",
        facility: "Unplug & revive",
      },
    ],
  },
};
