import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  formContainer: {
    backgroundColor: theme.palette.common.white,
  },
  leftContentWraper: {
    display: "flex",
    alignItems: "center",
  },
  heroContainer: {
    width: "100%",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    marginTop: -64,
    background: theme.palette.common.glance,
  },
  heroContent: {
    width: "90%",
    [theme.breakpoints.down("md")]: {
      width: "95%",
      marginBottom: theme.palette.spacing * 2.3,
    },
  },
  title: {
    marginBottom: "1rem",
    fontWeight: theme.typography.fontWeightBold,
    color: (props) =>
      props.textColor
        ? `${props.textColor}`
        : `${theme.palette.common.white} !important`,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
    },
  },
  subTitle: {
    marginBottom: "1rem",
    display: "block",
    color: (props) =>
      props.textColor
        ? `${props.textColor}`
        : `${theme.palette.common.white} !important`,
    maxWidth: (props) => (props.overlayFalse ? "40%" : "95%"),
    lineHeight: 1.5,
    [theme.breakpoints.down("md")]: {
      maxWidth: (props) => (props.overlayFalse ? "60%" : "60%"),
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: (props) => (props.overlayFalse ? "90%" : "90%"),
    },
  },
  overlayEffect: {
    color: "white",
    width: "100%",
    height: "100%",
    display: "grid",
    placeItems: "center",
    backgroundColor: (props) =>
      props.overlayFalse
        ? "tranparent"
        : props.bgColor
        ? props.bgColor
        : theme.palette.common.imageOverlay,
  },
  btn: {
    fontSize: "0.9rem",
    textTransform: "capitalize",
    padding: "2px 20px !important",
    borderRadius: "20px !important",

    "&:hover": {
      transform: "scale(1.1,1.1) !important",
      transition: ".2s ease-in-out !important",
    },
  },

  // video overlay
  bannerBg: {
    background: theme.palette.common.glance,
    height: "85vh !important",
    [theme.breakpoints.down("sm")]: {
      height: "90vh !important",
    },
  },

  parallaxChildren: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",
    justifyContent: "center",
    "& h1": { color: "white", fontSize: "4vw", fontWeight: "normal" },
  },
  video: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  summary: {
    padding: theme.palette.spacing * 2.5,
  },

  summaryTitle: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    [theme.breakpoints.up("md")]: {
      maxWidth: 100,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2em",
    },
  },
  summaryLabel: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "1.3em",
  },
  summaryDescription: {
    color: theme.palette.common.white,
    lineHeight: "1.5em",
    paddingTop: theme.palette.spacing * 0.7,
    fontWeight: theme.typography.fontWeightRegular,
    paddingRight: theme.palette.spacing * 1,
  },
  summaryIcon: {
    color: theme.palette.secondary.main,
    paddingRight: theme.palette.spacing * 1,
  },
  counter: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
    paddingRight: theme.palette.spacing,
  },
}));

export default useStyle;
