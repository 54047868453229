import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme => ({
    container: {
        margin: 'auto',
        maxWidth: '50vw',
        backgroundColor: theme.palette.common.white,
        [theme.breakpoints.down('md')]:{
            maxWidth: '80vw',            
        }
    },
    jobTitle: {
        marginTop: theme.palette.spacing*3,
        marginBottom: theme.palette.spacing*3,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.fonts.font20,
        color: theme.palette.common.primaryHover
    },
    requireTitle:{
        marginTop: theme.palette.spacing*6,
        marginBottom: theme.palette.spacing*6,
        fontWeight: theme.typography.fontWeightMedium,
        fontSize: theme.fonts.font20,
        color: theme.palette.common.secondaryHover,
    },
    jobTitleSpan: {
        color: theme.palette.common.grey
    },
    menualErrorFix: {
        fontSize: theme.fonts.font11,
        color: theme.palette.common.errorTextColor,
        height: theme.palette.spacing,
    },
    select: {
        fontSize: theme.fonts.font12,
        color: theme.palette.common.grey
    },
    phone: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'spacing-between'
    },
    btns: {
        marginTop: theme.palette.spacing*2,
        paddingBottom: theme.palette.spacing*4,
    },
    submitBtn: {
        marginRight: theme.palette.spacing*2,
        [theme.breakpoints.down('sm')]:{
            marginRight: theme.palette.spacing,
        }
    },
    resumeTitleSpan: {
        color: theme.palette.common.secondaryHover
    },
    fileContainer: {
        backgroundColor: theme.palette.common.fileUploaderBackground,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: theme.palette.spacing*3,
        paddingRight: theme.palette.spacing*3,
        borderRadius: 5,
        [theme.breakpoints.down('md')]:{
            flexDirection: 'column'
        }

    },
    dragAndDrop:{
        width: '50%',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]:{
            width: '100%',
            justifyContent: 'center',
        },
        [theme.breakpoints.down('xs')]:{
            display: 'none'
        }
    },
    list: {
        height: theme.palette.spacing,
        display: 'flex',

    },
    dragTitle: {
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightMedium,
    },
    fileNamePlaceholder: {
        color: theme.palette.common.grey,

    },
    fileName:{
        listStyle: 'none', 
        color: theme.palette.common.black, 

    },
    dndContainer:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('md')]:{
            alignItems: 'flex-end',
            
        }
    },
    or: {
        [theme.breakpoints.down('xs')]:{
            display: 'none'
        }
    },
    btnUpload: {
        [theme.breakpoints.down('md')]:{
            marginBottom: theme.palette.spacing*2
        },
        [theme.breakpoints.down('xs')]:{
            margin: theme.palette.spacing*2
        }
    }
}))

export default useStyles