import React from "react";
import { Grid } from "@material-ui/core";
import { motion } from "framer-motion";
import { Text, TeamMemberCard } from "./../../../Components";
import style from "./style";

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const TeamSection = ({ teamInfo, sectionTitle }) => {
  const classes = style();

  return (
    <>
      <Text align="center" className={classes.heading} variant="h3">
        {sectionTitle}
      </Text>
      <motion.div
        variants={container}
        initial="hidden"
        animate="visible"
        className="container"
      >
        <Grid container className={classes.container}>
          {teamInfo &&
            teamInfo.map(({ name, designation, imageUrl, socialLinks }, id) => (
              <TeamMemberCard
                name={name}
                designation={designation}
                imageUrl={imageUrl}
                key={id}
                socialLinks={socialLinks}
              />
            ))}
        </Grid>
      </motion.div>
    </>
  );
};
export default TeamSection;
