import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  iconWithTextBold: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.common.primary,
    fontWeight: theme.typography.fontWeightBold,
  },
  iconWithText: {
    display: "flex",
    alignItems: "start",
    color: theme.palette.common.white,
    marginBottom: theme.palette.spacing,
  },
  icon: {
    marginLeft: theme.palette.spacing,
    marginRight: theme.palette.spacing,
  },
  textColor: {
    color: `${theme.palette.common.white} !important`,
  },
}));

export default useStyles;
