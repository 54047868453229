import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  heroContainer: {
    width: "100%",
    backgroundSize: "cover",
    display: "flex",
    height: 350,
    marginTop: -8,
    backgroundImage: props => `url(${props.image})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    // backgroundSize: 'contain',
    [theme.breakpoints.down("sm")]: {
      backgroundImage: props => props.imageMobile && `url(${props.imageMobile})`,
    },
    [theme.breakpoints.down("md")]: {
      height : 300,
    },
    [theme.breakpoints.down("lg")]: {
      height : 350,
    },
    [theme.breakpoints.up("lg")]: {
      height : 450,
    },
  },
  heroContent: {
    width: props => props.overlayFalse ? "90%" :  "60%",
    [theme.breakpoints.down("md")]: {
      width: props => props.overlayFalse ? "95%" :  "95%",
    },
  },
  title: {
    marginBottom: "1rem",
    fontWeight: theme.typography.fontWeightBold,
    color: props => props.textColor ? `${props.textColor}` : `${theme.palette.common.white} !important`,
  },
  subTitle: {
    marginBottom: "1rem",
    display: "block",
    color: props => props.textColor ? `${props.textColor}` : `${theme.palette.common.white} !important`,
    maxWidth: props => props.overlayFalse? "40%" :"95%",
    lineHeight: 1.5,
    [theme.breakpoints.down("md")]: {
      maxWidth: props => props.overlayFalse ? "60%" :  "60%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: props => props.overlayFalse ? "90%" :  "90%",
    },
  },
  overlayEffect: {
    color: "white",
    width: "100%",
    height: "100%",
    display: "grid",
    placeItems: "center",
    backgroundColor: props=> props.overlayFalse ? 'tranparent' : props.bgColor? props.bgColor : theme.palette.common.imageOverlay,
    // [theme.breakpoints.down('xs')]:{
    //   backgroundColor: props => props.overlayFalse && theme.palette.common.imageOverlay,
    // }
  },

  btn: {
    marginRight: "1rem",
    fontSize: "0.9rem",
    textTransform: "capitalize",
  },
}));

export default useStyle;
