import React from 'react'
import {Typography} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'


const useStyle = makeStyles(theme => ({
    color:{
        color: theme.palette.common.primaryHover
    }
}))

const Text = ({children,className,...rest}) => {
    const style = useStyle()
    return (
        <Typography {...rest} className={style.color + ' ' + className}>
            {children}
        </Typography>
    )
}

export default Text
