import React,{useEffect, useState} from 'react';
import JobCreateForm from './JobCreateForm';
import {useHistory,useParams} from 'react-router-dom'

const CreateJob = () => {
  const {id} = useParams()
  const history = useHistory()
  const [render, setRender] = useState(false)


  useEffect(()=>{
    if (process.env.REACT_APP_JOBCREATE_ID === id) {
      const password =  prompt('Enter password')
      if (password === process.env.REACT_APP_JOBCREATE_PASSWORD) {
        setRender(true)
      }else{
        history.push('/career')
      }
      
    }else{
      history.push('/career')
    }
    // eslint-disable-next-line
  },[])
return (
  <>
  {render && <JobCreateForm /> }
  </>
)
  
}
  

export default CreateJob