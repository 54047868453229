import React from "react";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import RepeatIcon from "@material-ui/icons/Repeat";
import { Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Text from "../../Components/text/Text";
import { Count, CountIcon } from "../../Components";

const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.white.main,
    width: "100%",
  },
  innerContainer: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.common.imageOverlayRed,
    width: 180,
  },
  titleBold: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.black,
  },
}));

export const list = [
  {
    icon: <RepeatIcon fontSize="large" />,
    countNumber: 15,
    label: "Active Partners",
    summary: "We work closely with our clients and understand all the requirements to attain business and marketing goals.",
  },
  {
    icon: <AccessTimeIcon fontSize="large" />,
    countNumber: 10,
    label: "Years of Experience",
    summary:
      "We create diverse and complex software solutions for any business need. We will transform your ideas into reality.",
  },
  {
    icon: <DoneAllIcon fontSize="large" />,
    countNumber: 150,
    label: "Projects Completed",
    summary:
      "Our spectrum of development procedures includes web design, web migration, SEO, LMS, e-commerce and digital marketing.",
  },
  {
    icon: <AssignmentIndIcon fontSize="large" />,
    countNumber: 40,
    label: "IT Professionals",
  },
];

const CountSection = () => {
  const style = useStyle();
  return (
    <Box className={style.container} py={2}>
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
        >
          {list.map((item, index) => {
            return (
              <Box
                key={index}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                className={style.innerContainer}
              >
                <CountIcon>{item.icon}</CountIcon>
                <Box py={2}>
                  <Text variant="h2" className={style.titleBold}>
                    <Count end={item.countNumber} suffix="+" />
                  </Text>
                </Box>

                <Text variant="h5" className={style.titleBold}>
                  {item.label}
                </Text>
              </Box>
            );
          })}
        </Box>
      </Container>
    </Box>
  );
};

export default CountSection;
