import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  heading: {
    color: `${theme.palette.secondary.main} !important`,
    fontWeight: theme.typography.fontWeightMedium,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nameText: {
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down("md")]: {
      fontSize: theme.fonts.font16,
    },
  },
  designationText: {
    color: theme.palette.common.textColor,
    fontSize: theme.fonts.font13,
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: theme.palette.spacing * 3,
  },
  grid: {
    "&:hover": {
      "& $iconContainer": {
        opacity: 1,
        cursor: "pointer",
        transform: "translate(0px,0px)",
      },
    },
  },
  imageContainer: {
    height: 180,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      height: 150,
    },
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "auto",
    display: "block",
  },
  icons: {
    display: "flex",
    justifyContent: "center",
    marginLeft: theme.palette.spacing,
    marginRight: theme.palette.spacing,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  icon: {
    color: theme.palette.common.white,
    padding: theme.palette.spacing * 0.5,
    paddingLeft: theme.palette.spacing * 2,
    paddingRight: theme.palette.spacing * 2,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.palette.spacing,
      paddingRight: theme.palette.spacing,
    },
  },
  iconContainer: {
    position: "relative",
    zIndex: 5,
    marginTop: -theme.palette.spacing * 3,
    marginBottom: theme.palette.spacing * 3,
    transitions: "opacity ease-is",
    transitionDuration: "1s",
    opacity: 0.0,
    transform: "translate(0px,20px)",
  },
  extraSpacing: {
    marginTop: theme.palette.spacing,
  },
}));
export default useStyles;
