import {makeStyles} from '@material-ui/core/styles';
export const useStyle = makeStyles((theme)=>({
    margin:{
        "& br":{
            paddingTop:'10 !important',
            paddingBottom:'10 !important',
        }
        
    },
    fontBold:{
        fontWeight:theme.typography.fontWeightMedium
    },
    fontGrey:{
        color:`${theme.palette.common.warmGrey} !important`
    },
    btn:{
        fontSize: "0.9rem",
        textTransform: "capitalize",
    },
    activeTab:{
        backgroundColor:theme.palette.common.backgroundDark
    },
    input:{
        '& .MuiOutlinedInput-adornedEnd':{
            paddingRight: '10px !important'
        },
        '& .MuiOutlinedInput-root':{
            borderRadius: '10px !important'
        },
    },
    paperShadow:{
        ' & .MuiPaper-elevation1':{
            boxShadow:'none !important',
            borderBottom:`.5px solid ${theme.palette.common.warmGrey}`,
            borderRadius:'0px !important'
        },
    },
    paperShadowContainer:{
        ' & .MuiPaper-elevation1':{
            boxShadow:'none !important',
        },
    },
    quill: {
        height: '100px',
        marginBottom: '40px',
        paddingBottom: '10px',
      },
      error:{
          color:theme.palette.common.errorTextColor,
      }
}))

export const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
      ["code-block"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  export const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "code-block",
  ];
export const content = [
    {   jobId:1,
        company:"Mztech",
        location:"New York, USA",
        title: "Mern Stack Developer",
        jobType: "Full Time",
        deadline:"30th Sept.",
        summary:
      "Software Developers at MzTech are the backbone of our strategic initiatives to design, code, test, and provide industry-leading solutions that make the world run today.",
    imageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1621562057/MzTechCorp/careerContent/Job_Fullstack_x5thcu.png",
        minimumQulifications:
           ` 1. Bachelor's degree or equivalent  practical   experience <br> 

            2. 3 years of software development experience, or 8 years with an advanced degree <br>
            3. Experience with software development using Python. <br>
            4. Bachelor's degree or equivalent practical experience <br>
            5. Experience with applied Natural Language Processing. <br> `
        ,
        preferredQualifications:
            `1. Master’s degree or PhD in Engineering, Computer Science, or a related technical field. </br>
            2. 15 years of professional software development experience. </br>
            3. Experience with software development using C++. </br>
            4. Experienced with Tensorflow or similar frameworks. </br>
            5. Experience with computer vision models and approaches. </br>`
        ,
        aboutTheJob:
            `1. The most competitive profit-sharing bonus plans in the industry. We pay up to 50% of all profits monthly to all full-time employees!</br> 
            2. Joining our Coalition means you also get to enjoy paid time off and subsidized gym memberships.</br> 
            3. Living in LA? Flex days, free Friday lunches, regular company events, and higher base salaries are all extra benefits that our in-office team members enjoy.</br> 
            4. Our US-Based team members can enjoy our medical, dental, vision, and life insurance packages in all US states.</br> 
            5. Our international team members have the opportunity to participate in our International Insurance Reimbursement Program, a benefit unique to Coalition.</br> `
        ,
        
        responsibilities:
            `1. Transforming complex layout PSDs into pixel-perfect presentation-layer HTML5/CSS3 templates </br>
            2. Creating responsive website designs </br>
            3. Building websites with WordPress, extending and developing plugins and themes </br>
            4. Working with Photoshop, Illustrator, and Fireworks to create images optimized for the web </br>
            5. Working with version control systems such as GIT / SVN </br>
            6. Handling multiple projects at the same time </br>
            7. Producing high quality of work with a strong focus on detail </br>`
        ,
        facilities:
            `1. Competitive Salary </br>
            2. Ongoing training and growth opportunities. </br>
            3. A Best Place to Work winner multiple times where we focus on creating a great employee experience </br>
            4. Rock solid medical, dental, and vision plans. </br>
            5. Mental Health Coverage - we offer several programs to support your mental health and wellness goals. </br>
            6. weeks paid vacation; paid holidays; we expect you to work hard, but still enjoy your personal life </br>
            7. weeks of baby bonding time for all new parents (within the first year of birth or adoption), 8 Weeks of Paid Pregnancy Leave. </br>
            8. Employer-contributing student loan assistance program. </br>`
        ,

    },
    {   jobId:2,
        company:"Mztech",
        location:"New York, USA",
        title: "Software Engineer Intern",
        jobType: "Part-time",
        deadline:"12th dec.",
        summary:
      "MzTech is seeking interns/co-ops to join our engineering team. As a Software Engineer Intern, you'll drive the development of systems behind MzTechs's products and services.",
    imageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1621562057/MzTechCorp/careerContent/Job_SWE_group_uburdh.png",
        minimumQulifications:
           ` 1. Bachelor's degree or equivalent  practical   experience <br> 

            2. 5 years of software development experience, or 8 years with an advanced degree <br>
            3. Experience with software development using Python. <br>
            4. Bachelor's degree or equivalent practical experience <br>
            5. Experience with applied Natural Language Processing. <br> `
        ,
        preferredQualifications:
            `1. Master’s degree or PhD in Engineering, Computer Science, or a related technical field. </br>
            2. 15 years of professional software development experience. </br>
            3. Experience with software development using C++. </br>
            4. Experienced with Tensorflow or similar frameworks. </br>
            5. Experience with computer vision models and approaches. </br>`
        ,
        aboutTheJob:
            `1. The most competitive profit-sharing bonus plans in the industry. We pay up to 50% of all profits monthly to all full-time employees!</br> 
            2. Joining our Coalition means you also get to enjoy paid time off and subsidized gym memberships.</br> 
            3. Living in LA? Flex days, free Friday lunches, regular company events, and higher base salaries are all extra benefits that our in-office team members enjoy.</br> 
            4. Our US-Based team members can enjoy our medical, dental, vision, and life insurance packages in all US states.</br> 
            5. Our international team members have the opportunity to participate in our International Insurance Reimbursement Program, a benefit unique to Coalition.</br> `
        ,
        
        responsibilities:
            `1. Transforming complex layout PSDs into pixel-perfect presentation-layer HTML5/CSS3 templates </br>
            2. Creating responsive website designs </br>
            3. Building websites with WordPress, extending and developing plugins and themes </br>
            4. Working with Photoshop, Illustrator, and Fireworks to create images optimized for the web </br>
            5. Working with version control systems such as GIT / SVN </br>
            6. Handling multiple projects at the same time </br>
            7. Producing high quality of work with a strong focus on detail </br>`
        ,
        facilities:
            `1. Competitive Salary </br>
            2. Ongoing training and growth opportunities. </br>
            3. A Best Place to Work winner multiple times where we focus on creating a great employee experience </br>
            4. Rock solid medical, dental, and vision plans. </br>
            5. Mental Health Coverage - we offer several programs to support your mental health and wellness goals. </br>
            6. weeks paid vacation; paid holidays; we expect you to work hard, but still enjoy your personal life </br>
            7. weeks of baby bonding time for all new parents (within the first year of birth or adoption), 8 Weeks of Paid Pregnancy Leave. </br>
            8. Employer-contributing student loan assistance program. </br>`
        ,

    },
    {   jobId:3,
        company:"Mztech",
        location:"New York, USA",
        title: "UX / UI Engineer",
        jobType: "Remote",
        deadline:"8th July",
        summary:
      "Thomas J. Watson said it best: “Good design is good business.”  Designers at MzTech work to create experiences that work together, work the same, and work for our clients.",
    imageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1621562057/MzTechCorp/careerContent/Job_UX_Designer_ja8adg.png",
        minimumQulifications:
           ` 1. Bachelor's degree or equivalent  practical   experience <br> 

            2. 10 years of software development experience, or 8 years with an advanced degree <br>
            3. Experience with software development using Python. <br>
            4. Bachelor's degree or equivalent practical experience <br>
            5. Experience with applied Natural Language Processing. <br> `
        ,
        preferredQualifications:
            `1. Master’s degree or PhD in Engineering, Computer Science, or a related technical field. </br>
            2. 15 years of professional software development experience. </br>
            3. Experience with software development using C++. </br>
            4. Experienced with Tensorflow or similar frameworks. </br>
            5. Experience with computer vision models and approaches. </br>`
        ,
        aboutTheJob:
            `1. The most competitive profit-sharing bonus plans in the industry. We pay up to 50% of all profits monthly to all full-time employees!</br> 
            2. Joining our Coalition means you also get to enjoy paid time off and subsidized gym memberships.</br> 
            3. Living in LA? Flex days, free Friday lunches, regular company events, and higher base salaries are all extra benefits that our in-office team members enjoy.</br> 
            4. Our US-Based team members can enjoy our medical, dental, vision, and life insurance packages in all US states.</br> 
            5. Our international team members have the opportunity to participate in our International Insurance Reimbursement Program, a benefit unique to Coalition.</br> `
        ,
        
        responsibilities:
            `1. Transforming complex layout PSDs into pixel-perfect presentation-layer HTML5/CSS3 templates </br>
            2. Creating responsive website designs </br>
            3. Building websites with WordPress, extending and developing plugins and themes </br>
            4. Working with Photoshop, Illustrator, and Fireworks to create images optimized for the web </br>
            5. Working with version control systems such as GIT / SVN </br>
            6. Handling multiple projects at the same time </br>
            7. Producing high quality of work with a strong focus on detail </br>`
        ,
        facilities:
            `1. Competitive Salary </br>
            2. Ongoing training and growth opportunities. </br>
            3. A Best Place to Work winner multiple times where we focus on creating a great employee experience </br>
            4. Rock solid medical, dental, and vision plans. </br>
            5. Mental Health Coverage - we offer several programs to support your mental health and wellness goals. </br>
            6. weeks paid vacation; paid holidays; we expect you to work hard, but still enjoy your personal life </br>
            7. weeks of baby bonding time for all new parents (within the first year of birth or adoption), 8 Weeks of Paid Pregnancy Leave. </br>
            8. Employer-contributing student loan assistance program. </br>`
        ,

    }
]


export const teamGroupJobPost = [
  {
    jobId: 1,
    title: "UI UX",
    summary:
      "Software Developers at MzTech are the backbone of our strategic initiatives to design, code, test, and provide industry-leading solutions that make the world run today.",
    imageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1621562057/MzTechCorp/careerContent/Job_UX_Designer_ja8adg.png",
  },
  {
    jobId: 2,
    title: "Software Developer",
    summary:
      "Software Developers at MzTech are the backbone of our strategic initiatives to design, code, test, and provide industry-leading solutions that make the world run today.",
    imageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1621562057/MzTechCorp/careerContent/Job_SWE_group_uburdh.png",
  },
  {
    jobId: 3,
    title: "Marketing",
    summary:
      "Software Developers at MzTech are the backbone of our strategic initiatives to design, code, test, and provide industry-leading solutions that make the world run today.",
    imageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1621562057/MzTechCorp/careerContent/Job_Marketing_group_nja8xa.png",
  },
];
