import React,{useEffect} from 'react';
import { Container,Box } from '@material-ui/core';
import {Text} from '../../Components'
import FormHeader from './FormHeader'
import useStyles from './style'
import useFetch from './../../Helpers/customHooks/useFetch'
import FormBody from './FormBody'
import {useParams} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {getSingleJob,jobSliceSelector} from "./../../Redux/Slices/jobSlice";
const Career = () => {
    const pageContent = useFetch('Career')
    const classes = useStyles()
    const {id} = useParams()
    const dispatch = useDispatch();
    const { singleJob,loading } = useSelector(jobSliceSelector);

    useEffect(() => {
        dispatch(getSingleJob(id))
        //eslint-disable-next-line
    }, [])
    
    if (loading) {
        return <Box my={10}>
          <Text variant='h3' align='center'>Loading...</Text>
        </Box>
      }
    return (
        <Box mt={10}>
            <Container className={classes.Container}>
            <FormHeader 
                header = {pageContent?.Career?.Header}
                title={singleJob?.title}
            />
            <FormBody 
                resume = {pageContent?.Career?.Resume}
            />
        </Container>
        </Box>
    )
}
export default Career