import { combineReducers } from "redux";
import pageContentReducers from "./../Slices/pageContentSlice";
import jobSliceReducers from "./../Slices/jobSlice";
import homePageContentReducers from "./../Slices/homePageContentSlice";

export default combineReducers({
  pageContent: pageContentReducers,
  homePageContent: homePageContentReducers,
  jobSlice:jobSliceReducers
});
