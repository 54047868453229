import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
    container: {
        marginBottom: theme.palette.spacing*8,
        width: 250,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        transition: '0.5s',
        overflow: 'hidden !important',
        '&:hover':{
            backgroundColor: props => props.backgroundColor,
            '& svg path': { 
                fill: theme.palette.white.main,
            },
            '& p': {
                color: `${theme.palette.white.main} !important`
            }
        }
    },
    facility: {
        fontSize: theme.fonts.font18,
        maxWidth: 170,
        color: props => props.backgroundColor,
        [theme.breakpoints.down('sm')]:{
            paddingLeft: theme.palette.spacing*2,
            paddingRight:  theme.palette.spacing*2,
        },
        [theme.breakpoints.down('xs')]:{
            paddingLeft: theme.palette.spacing,
            paddingRight:  theme.palette.spacing,
        },
    },
    icon: {
        marginBottom: theme.palette.spacing,
        marginTop: theme.palette.spacing*2,
        '& svg':{
            height: 60
        },
        "& svg path": { fill: props => props.backgroundColor }
    } 
}));

export default useStyle