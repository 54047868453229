import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactSVG } from "react-svg";
import { Box } from "@material-ui/core";
import sliderSettings from "./sliderSetting";
import style from "./style";

const LogosSlider = ({ rtl, content }) => {
  const classes = style();

  const settings = sliderSettings(rtl);

  return (
    <Box mb={5} className={classes.logosContainer}>
      <Slider {...settings} className={classes.sliderGradian}>
        {content &&
          content.length > 0 &&
          content.map((logo, index) => (
            <ReactSVG
              key={index}
              className={classes.logo}
              src={logo}
              alt="MzTech"
            />
          ))}
      </Slider>
    </Box>
  );
};

export default LogosSlider;
