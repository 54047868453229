import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";

export const useStyle = makeStyles((theme) => ({
  height: {
    height: 350,
    [theme.breakpoints.down("md")]: {
      height: 300,
    },
  },
  background: {
    backgroundColor: (props) =>
      props.bgColor ? props.bgColor : theme.palette.common.greyishBackground,
  },
  technologyImageSize: {
    width: "100%",
    height: 150,
    objectFit: "contain",
    display: "flex !important",
    justifyContent: "center",
    alignItems: "contain",
    [theme.breakpoints.down("sm")]: {
      width: 120,
      height: 120,
    },
    [theme.breakpoints.down("xs")]: {
      width: 100,
      height: 100,
    },
  },
  technologyContainer: {
    width: "100%",
    height: 150,
    display: "flex !important",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: 120,
      height: 120,
    },
    [theme.breakpoints.down("xs")]: {
      width: 100,
      height: 100,
    },
  },
  toolName: {
    fontSize: theme.fonts.font15,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.fonts.font12,
    },
  },
  sliderContainer: {
    marginLeft: theme.palette.spacing * 2,
    marginRight: theme.palette.spacing * 2,
  },
  icon: {
    "& svg": {
      [theme.breakpoints.down("sm")]: {
        height: 80,
      },
      [theme.breakpoints.down("xs")]: {
        height: 60,
      },
    },
  },
  titleBold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  projectImageSize: {
    width: "100%",
    height: "auto",
    objectFit: "contain",
  },
}));

export const content = [
  {
    imageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1620330742/Mztech/domenico-loia-hGV2TfOh0ns-unsplash_s3voaz.jpg",
    title: "App Development",
    subTitle:
      "Developing innovative and native mobile Apps for Android, iOS, BlackBerry and Windows platforms.",
  },
  {
    title: "MzTech Service",
    headline: "App Development",
    backgroundColor: theme.palette.common.secondaryHover,
    height: 320,
    slider: false,
    description:
      "With a large portfolio of industry leading mobile application development services, we know how to translate our solutions into the result of your business growth. We work on cross-platform, responsive mobile apps. Where others struggle, we thrive for the most attractive user interface and secure backend. Team Brain Station 23 provides the cutting-edge mobile app development service to diverse industries and clients including large scale organizations and startups similar to Uber.",
    imageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1620831226/MzTechCorp/portfolio%20pic/first_mobile_zeaemu.png",
  },
  {
    title: "We Offered",
    offerings: [
      {
        icon: "https://res.cloudinary.com/dv244dkbr/image/upload/v1620906137/MzTechCorp/icons/quality_bnndcs.svg",
        facility: "High-quality applications for a wide array of niche",
      },
      {
        icon: "https://res.cloudinary.com/dv244dkbr/image/upload/v1620906137/MzTechCorp/icons/Outline_wuhvpq.svg",
        facility: "Attracting a larger user base & increasing business impact",
      },
      {
        icon: "https://res.cloudinary.com/dv244dkbr/image/upload/v1620906136/MzTechCorp/icons/phone_i61je2.svg",
        facility: "High standard functionality & features",
      },
      {
        icon: "https://res.cloudinary.com/dv244dkbr/image/upload/v1620906136/MzTechCorp/icons/Application_fpgvji.svg",
        facility: "Enhancing visual appeal & Rich user interface",
      },
      {
        icon: "https://res.cloudinary.com/dv244dkbr/image/upload/v1620906136/MzTechCorp/icons/cross-platform_xk7fsa.svg",
        facility: "Cross-platform development for mobile apps",
      },
      {
        icon: "https://res.cloudinary.com/dv244dkbr/image/upload/v1620906136/MzTechCorp/icons/cross-platform_xk7fsa.svg",
        facility: "Variety of app choices from native, web & hubrid",
      },
      {
        icon: "https://res.cloudinary.com/dv244dkbr/image/upload/v1620906137/MzTechCorp/icons/application_1_hncuxh.svg",
        facility: "Complete operating system support",
      },
    ],
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1621181886/MzTechCorp/portfolio%20pic/multiple_mobile_screen_xzdvoc.png",
  },
  {
    title: "We Utilize",
    imageUrl: [
      "https://res.cloudinary.com/dv244dkbr/image/upload/c_fit,h_150,w_150/v1621140386/MzTechCorp/portfolio%20pic/react-native4_vyzfzr.jpg",
      "https://res.cloudinary.com/dv244dkbr/image/upload/c_fit,h_150,w_150/v1621133534/MzTechCorp/portfolio%20pic/material-ui_i2lnow.png",
      "https://res.cloudinary.com/dv244dkbr/image/upload/c_fit,h_150,w_150/v1621133507/MzTechCorp/portfolio%20pic/firebase2_e7clxh.png",
      "https://res.cloudinary.com/dv244dkbr/image/upload/c_fit,h_150,w_150/v1621133544/MzTechCorp/portfolio%20pic/mongodb_u6dfbp.png",
    ],
  },
  {
    title: "Portfolio",
    headline: "Mobile App",
    backgroundColor: theme.palette.common.secondaryHover,
    height: 200,
    slider: true,
    description:
      "With a large portfolio of industry leading mobile application development services, we know how to translate our solutions into the result of your business growth",
    imageUrl: [
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1620831226/MzTechCorp/portfolio%20pic/first_mobile_zeaemu.png",
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1620831226/MzTechCorp/portfolio%20pic/first_mobile_zeaemu.png",
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1620831226/MzTechCorp/portfolio%20pic/first_mobile_zeaemu.png",
    ],
  },
  {
    title: "Who we've worked with",
    imageUrl: [
      "https://res.cloudinary.com/dv244dkbr/image/upload/c_fit,h_150,w_150/v1621178292/MzTechCorp/portfolio%20pic/logo3_qc6sh0.png",
      "https://res.cloudinary.com/dv244dkbr/image/upload/c_fit,h_150,w_150/v1621178291/MzTechCorp/portfolio%20pic/logo1_mh1by8.png",
      "https://res.cloudinary.com/dv244dkbr/image/upload/c_fit,h_150,w_150/v1621178291/MzTechCorp/portfolio%20pic/logo5_nmiex9.jpg",
      "https://res.cloudinary.com/dv244dkbr/image/upload/c_fit,h_150,w_150/v1621178291/MzTechCorp/portfolio%20pic/logo4_rpi6du.png",
      "https://res.cloudinary.com/dv244dkbr/image/upload/c_fit,h_150,w_150/v1621178291/MzTechCorp/portfolio%20pic/logo2_js7ygu.png",
    ],
  },
];
