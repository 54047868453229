import Typed from "react-typed";
import { Text } from "..";
import { makeStyles } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";
import { useEffect } from "react";
import AOS from "aos";

const useStyle = makeStyles((theme) => ({
  text: {
    marginBottom: "1rem",
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    fontSize: "2rem",
    [theme.breakpoints.down("md")]: {
      fontSize: "1.563rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      marginBottom: 0,
      padding: 0,
    },
  },
  container: {
    display: "flex",
    marginTop: theme.palette.spacing * 5,
    marginBottom: theme.palette.spacing * 2,
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.palette.spacing * 2,
      marginBottom: theme.palette.spacing * 2,
    },
    "& span": {
      fontWeight: theme.typography.fontWeightBold,
      color: (props) => `${props.color} !important`,
      marginTop: -theme.palette.spacing * 0.25,
      fontSize: "2rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "1.563rem",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.25rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.3rem",
      },
    },
  },
  typed: {
    marginLeft: theme.palette.spacing,
    "& span": {
      background: "-webkit-linear-gradient(0deg, #e53363, #7c0e2c)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
}));

const services = [
  "E-commerce",
  "Web Migration",
  "App Development",
  "Search Engine Optimization",
  "Web Design and Services",
  "Cloud Server Setup",
  "Learning Management System",
  "Software Development",
  "Web Speed Optimization",
  "Digital Marketing",
];
const TypeWriterEffect = (props) => {
  const classes = useStyle(props);

  useEffect(() => {
    AOS.init({
      offset: 1200,
      duration: 600,
      easing: "linear",
      mirror: "false",
      // delay: 100,
    });
  }, []);

  return (
    <div className={classes.container} data-aos="fade-up" data-aos-delay="200">
      <Hidden xsDown>
        <Text className={classes.text} variant="h2">
          {`Services | `}
        </Text>
      </Hidden>
      <Typed
        className={classes.typed}
        strings={services}
        loop
        backDelay={1000}
        startDelay={1000}
        typeSpeed={70}
        backSpeed={70}
      />
    </div>
  );
};
export default TypeWriterEffect;
