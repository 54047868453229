import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: (props) =>
      props.home ? "rgba(179, 41, 78, 0.7);" : "transparent",
    borderRadius: 10,
    padding: 10,
    // height: (props) => props.home && "42vh",
    maxWidth: (props) => props.home && "350px",
    color: (props) =>
      props.home ? `${theme.palette.common.white} !important` : "",

    "& label": {
      color: (props) => props.home && "white",
      paddingLeft: (props) => props.home && 3,
    },
    "& input, textarea": {
      //   lineHeight: "1em !important",
      paddingLeft: (props) => props.home && 3,
      backgroundColor: (props) =>
        props.home && "rgba(0, 0, 0, 0.19) !important",
      color: (props) => props.home && "white",
    },

    "& textarea": {
      marginTop: -5,
    },
  },
  contactUs: {
    backgroundColor: theme.palette.common.primaryHover,
  },
  sectionTitle: {
    color: (props) =>
      props.home
        ? `${theme.palette.common.white} !important`
        : `${theme.palette.common.primaryHover} !important`,
    textAlign: "start",
    marginLeft: (props) => (props.home ? 0 : -theme.palette.spacing * 2),
    padding: (props) => (props.home ? 0 : theme.palette.spacing * 2),
    paddingTop: (props) => (props.home ? 15 : 10),
  },
  submitBtnStyle: {
    textTransform: "capitalize !important",
    marginTop: (props) => (props.home ? 10 : 20),
    padding: (props) => (props.home ? "0px 30px" : "5px 35px"),
    borderRadius: "20px",
    transform: "scale(1.1)",
    background: (props) =>
      props.home && "linear-gradient(174deg, #6df7b1, #127eed)",
    "&:hover": {
      transform: "scale(1.3)",
      transition: ".2s ease-in-out",
    },
  },
  error: {
    color: `${theme.palette.common.errorTextColor} !important`,
    fontSize: theme.fonts.font12,
    margin: theme.palette.spacing * 0.4,
  },
  menualErrorFix: {
    fontSize: theme.fonts.font12,
    color: (props) =>
      props.home ? "#34ffff" : theme.palette.common.errorTextColor,
    height: theme.palette.spacing,
  },
  btnStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: (props) => !props.home && theme.palette.spacing * 6,
  },
}));

export default useStyles;
