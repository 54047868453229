import React, { useState } from "react";
import { Container, Grid, Box, makeStyles } from "@material-ui/core";
import { ImageOverlay, TeamNavbar, Section } from "./../../Components";
import TeamSection from "./TeamSection";
import image from "./../../images/img3.jpg";
import useFetch from "../../Helpers/customHooks/useFetch";


const useStyles = makeStyles((theme) => ({
  hero: {
    height: 350,
    [theme.breakpoints.down("md")]: {
      height: 300,
    },
    [theme.breakpoints.down("xs")]: {
      height: 250,
    },
  },
  background: {
    backgroundColor: theme.palette.common.greyishBackground,
    backgroundImage: `url(https://res.cloudinary.com/dv244dkbr/image/upload/v1620710853/MzTechCorp/icons/logo_opacity_10_with_padding_is5zlb.svg)`
  }
}));

const TeamPage = () => {
  const classes = useStyles();
  const pageContent = useFetch('Our Team')

  const [navState, setNavState ] = useState({
    value: {
      all: 'all',
      founders: 'founders',
      humanResource: 'humanResource',
      marketing: 'marketing',
      uiux: 'uiux',
      webDevelopment: 'webDevelopment',
      appDevelopment: 'appDevelopment',
    },
    isClicked: {
      all: true,
      founders: false,
      humanResource: false,
      marketing: false,
      uiux: false,
      webDevelopment: false,
      appDevelopment: false,
    }
  })

  return (
    <>
      <Grid container>
        <Grid item className={classes.hero} xs={12}>
          <ImageOverlay
            image={image}
            title="Our People"
            subTitle="We support IT service industries across North America. Our marketplace includes thousands of qualified service providers with a range of skillsets ready to help you get work done."
          />
        </Grid>
      </Grid>
      <Section title={"Our People"} />
      <div className={classes.background}>
        <Container justify="center" maxWidth="md">
          <Box
            component={Grid}
            item
            md={12}
            display={{ xs: "none", sm: "block" }}
          >
            <TeamNavbar
              navState={navState}
              setNavState={setNavState}
            />
          </Box>

          {/* Founder */}
          {(navState.isClicked.all || navState.isClicked.founders) && 
            <TeamSection
              key = {1} 
              teamInfo={pageContent?.TeamInfo?.Founders} 
              sectionTitle={'Founder'}
            />
          }

          {/* Human Resource */}
          {/* {(navState.isClicked.all || navState.isClicked.humanResource) && 
            <TeamSection
              key = {2} 
              teamInfo={pageContent?.TeamInfo?.HumanResource} 
              sectionTitle={'Human Resource'}
            />
          } */}

          {/* Marketing & Administration */}
          {(navState.isClicked.all || navState.isClicked.marketing) && 
            <TeamSection
              key = {3} 
              teamInfo={pageContent?.TeamInfo?.MarketingAdministration} 
              sectionTitle={'Marketing & Administration'}
            />
          }

          {/* Marketing & Administration */}
          {(navState.isClicked.all || navState.isClicked.uiux) && 
            <TeamSection
              key = {4} 
              teamInfo={pageContent?.TeamInfo?.UIUX} 
              sectionTitle={'UI & UX'}
            />
          }

          {/* Web Developers */}
          {(navState.isClicked.all || navState.isClicked.webDevelopment) && 
            <TeamSection
              key = {5} 
              teamInfo={pageContent?.TeamInfo?.WebDevelopers} 
              sectionTitle={'Web Developers'}
            />
          }

          {/* App Developers */}
          {(navState.isClicked.all || navState.isClicked.appDevelopment) && 
            <TeamSection
              key = {6} 
              teamInfo={pageContent?.TeamInfo?.AppDevelopers} 
              sectionTitle={'App Developers'}
            />
          }
          </Container>
      </div>
    </>
  );
};
export default TeamPage;
