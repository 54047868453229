import React,{useState} from 'react';
import {Container,Grid,Box,MenuItem,Switch} from '@material-ui/core';
import {Input,Text,Button,uploadImages,FileDrop} from '../../Components';
import { useForm, Controller } from "react-hook-form";
import ReactQuill from 'react-quill';
import {useStyle} from './Utility';
import { CircularProgress } from '@material-ui/core';
import { useDispatch } from "react-redux";
import {createJob} from "./../../Redux/Slices/jobSlice";

const JobCreateForm = () => {
  const classes = useStyle()
  const [files,setFiles] = useState([])
  const [loading, setloading] = useState(false)
  
    const { handleSubmit, control,formState: { errors } } = useForm();
    const dispatch = useDispatch();
  
    const onSubmit = async data => {
        setloading(true)
      data.image =  await uploadImages(files, 'jobImage');
      await dispatch(createJob(data));
      setloading(false)
     
    };

    const onDrop =(file)=>{
      setFiles(file)
      
    }
    
    return (
        <Box my={3}>
          <Container>
            <Box pb={5}>
              <Text variant='h3' align='center' className={classes.fontBold} >Create Job</Text>
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>

                <Grid item xs={12} sm={3}>
                <Controller
                name="company"
                control={control}
                rules={{ required: 'Company name required' }}
                render={({ field }) => <Input 
                {...field}
                type='text'
                variant="outlined"
                
                fullWidth
                size="small"
                label='Company Name'
                  />}
                />
                <Box className={classes.error} my={1}>
                   {errors?.company && errors?.company?.message}
                   </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                <Controller
                name="location"
                control={control}
                rules={{ required: 'Company address required' }}
                render={({ field }) => <Input 
                {...field}
                type='text'
                variant="outlined"
                
                fullWidth
                size="small"
                label='Company address'
                  />}
                />
                <Box className={classes.error} my={1}>
                   {errors?.location && errors?.location?.message}
                   </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                <Controller
                name="title"
                control={control}
                rules={{ required: 'Job title required' }}
                render={({ field }) => <Input 
                {...field}
                type='text'
                variant="outlined"
                
                fullWidth
                size="small"
                label='Job title'
                  />}
                />
                <Box className={classes.error} my={1}>
                   {errors?.title && errors?.title?.message}
                   </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                <Controller
                name="category"
                control={control}
                rules={{ required: 'Select job catagory' }}
                render={({ field }) => (
                  
                    <Input {...field}
                    variant='outlined'
                    fullWidth
                    select
                    size="small"
                    label='Job category'
                   defaultValue=''
                    >
                      <MenuItem value=''><em>None</em></MenuItem>
                      <MenuItem value='UX / UI'>UX / UI</MenuItem>
                      <MenuItem value='Software developer'>Software developer</MenuItem>
                      <MenuItem value='Marketing'>Marketing</MenuItem>
                    </Input>
                  )}
                />
                <Box className={classes.error} my={1}>
                   {errors?.category && errors?.category?.message}
                   </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                <Controller
                name="JobType"
                control={control}
                rules={{ required: 'Select job type' }}
                render={({ field }) => (
                  
                    <Input {...field}
                    variant='outlined'
                    fullWidth
                    select
                    size="small"
                    label='Job Type'
                   defaultValue=''
                    >
                      <MenuItem value=''><em>None</em></MenuItem>
                      <MenuItem value='Full time'>Full Time</MenuItem>
                      <MenuItem value='Per time'>Per Time</MenuItem>
                      <MenuItem value='Internship'>Internship</MenuItem>
                      <MenuItem value='Remote'>Remote</MenuItem>
                    </Input>
                  )}
                />
                <Box className={classes.error} my={1}>
                   {errors?.JobType && errors?.JobType?.message}
                   </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                <Controller
                name="deadline"
                control={control}
                rules={{ required: 'Apply deadline required' }}
                render={({ field }) => (
                    <Input {...field}
                    variant='outlined'
                    fullWidth
                    label="Deadline"
                    type="date"
                    defaultValue="2021-06-24"
                    size="small"
                    
                    
                    />
                     
                  )}
                />
                 <Box className={classes.error} my={1}>
                   {errors?.deadline && errors?.deadline?.message}
                   </Box>
                </Grid>
                <Grid item xs={6} sm={4}>
                    <FileDrop
                    onChange = {onDrop}
                    value={files}
                    />

                 <Box className={classes.error} my={2}>
                   {errors?.image && errors?.image?.message}
                   </Box>
                </Grid>
                <Grid item xs={6} sm={1}>
                <Box display='flex' alignItems='center'>
                <Box >
                    <Text  className={classes.fontBold} >Publish</Text>
                  </Box>
               <Box >
               <Controller
                name="isPublished"
                control={control}
                label='isPublished'
                // rules={{ required: 'Apply deadline required' }}
                render={({ field }) => (
                  
                    <Switch  
                    {...field}
                    color='secondary'
                    
                    />
                     
                  )}
                  
                />
               </Box>

                </Box>
                </Grid>

                <Grid item xs={12} >
                  <Box my={1}>
                    <Text  className={classes.fontBold} >Summery</Text>
                  </Box>
                  <Controller
                      name="summary"
                      control={control}
                      rules={{ required: 'Describe job summery' }}
                      defaultValue=''
                      render={({ field }) => (
                        <ReactQuill {...field} className={classes.quill}
                        placeholder='Summary'
                         />
                      )}
                   />
                   <Box className={classes.error}>
                   {errors?.summary && errors?.summary?.message}
                   </Box>

                </Grid>

                <Grid item xs={12} >
                  <Box my={1}>
                    <Text  className={classes.fontBold} >Minimum Qulifications</Text>
                  </Box>
                  <Controller
                      name="minimumQulifications"
                      control={control}
                      rules={{ required: 'Describe Minimum Qulifications' }}
                      defaultValue=''
                      render={({ field }) => (
                        <ReactQuill {...field} className={classes.quill}
                        placeholder='Minimum Qulifications'
                         />
                      )}
                   />
                   <Box className={classes.error}>
                   {errors?.minimumQulifications && errors?.minimumQulifications?.message}
                   </Box>

                
                </Grid>

                <Grid item xs={12} >
                  <Box my={1}>
                    <Text  className={classes.fontBold} >Preferred Qualifications</Text>
                  </Box>

                  <Controller
                      name="preferredQualifications"
                      control={control}
                      rules={{ required: 'Describe preferred Qualifications' }}
                      defaultValue=''
                      render={({ field }) => (
                        <ReactQuill {...field} className={classes.quill}
                        placeholder='Preferred Qualifications'
                         />
                      )}
                   />
                   <Box className={classes.error}>
                   {errors?.preferredQualifications && errors?.preferredQualifications?.message}
                   </Box>
                </Grid>

                <Grid item xs={12} >
                  <Box my={1}>
                    <Text  className={classes.fontBold} >About The Job</Text>
                  </Box>

                  <Controller
                      name="aboutTheJob"
                      control={control}
                      rules={{ required: 'Describe about the job' }}
                      defaultValue=''
                      render={({ field }) => (
                        <ReactQuill {...field} className={classes.quill}
                        placeholder='About The Job'
                         />
                      )}
                   />
                   <Box className={classes.error}>
                   {errors?.aboutTheJob && errors?.aboutTheJob?.message}
                   </Box>

                </Grid>

                <Grid item xs={12} >
                  <Box my={1}>
                    <Text  className={classes.fontBold} >Responsibilities</Text>
                  </Box>

                  <Controller
                      name="responsibilities"
                      control={control}
                      rules={{ required: 'Describe job responsibilities' }}
                      defaultValue=''
                      render={({ field }) => (
                        <ReactQuill {...field} className={classes.quill}
                        placeholder='Responsibilities'
                         />
                      )}
                   />
                   <Box className={classes.error}>
                   {errors?.responsibilities && errors?.responsibilities?.message}
                   </Box>
                </Grid>

                <Grid item xs={12} >
                  <Box my={1}>
                    <Text  className={classes.fontBold} >Facilities</Text>
                  </Box>

                  <Controller
                      name="facilities"
                      control={control}
                      rules={{ required: 'Describe job facilities' }}
                      defaultValue=''
                      render={({ field }) => (
                        <ReactQuill {...field} className={classes.quill}
                        placeholder='Facilities'
                         />
                      )}
                   />
                   <Box className={classes.error}>
                   {errors?.facilities && errors?.facilities?.message}
                   </Box>

                </Grid>

                <Grid item xs={12} sm={6}>
                <Button type='submit' backgroundColor='#02334D' textColor='#fff' className={classes.btn}>{loading ? <CircularProgress size={20}  style={{ color: 'white' }} /> : 'Submit'}</Button>
                </Grid> 

                
            </Grid>
            </form>
        </Container>
        </Box>
    )
}

export default JobCreateForm


