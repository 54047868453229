import {
  Container,
  Grid,
  makeStyles,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Text, Input, Button } from "./../../Components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { generateName } from "./../../Helpers/helperFunctions";
import { firestore } from "./../../Helpers/Firebase";
import emailjs from "emailjs-com";

const useStyle = makeStyles((theme) => ({
  container: {
    padding: theme.palette.spacing * 5,
  },
  formContainer: {
    marginTop: theme.palette.spacing * 3,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.palette.spacing * 3,
    marginBottom: theme.palette.spacing * 3,
  },
  description: {
    color: `${theme.palette.common.warmGrey} !important`,
    marginBottom: theme.palette.spacing * 3,
  },
  menualErrorFix: {
    fontSize: theme.fonts.font11,
    color: theme.palette.common.errorTextColor,
    height: theme.palette.spacing,
  },
  submitBtn: {
    textTransform: "capitalize",
    marginTop: theme.palette.spacing * 2,
    marginBottom: theme.palette.spacing * 3,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.palette.spacing,
      marginBottom: theme.palette.spacing * 2,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.fonts.font11,
    },
  },
}));

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please provide a valid Email.")
    .required("Email is a required field"),
  message: yup.string().required("Message is a required field"),
});

const GetInTouch = ({ id }) => {
  const classes = useStyle();
  const currentService = generateName(id);
  const [services, setServices] = useState(currentService);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const getFormData = async (data, e) => {
    const query = { ...data, services };
    await firestore.collection("messagesFromService").add({ query });
    emailjs
      .sendForm(
        "service_w2ra9kk",
        "template_eyf7vcq",
        "#contact-form",
        "user_GMhoPw1R4j8TfJkac4GRa"
      )
      .then(
        (result) => {
          console.log("message sent succesfully");
        },
        (error) => {
          console.log("message wasn't send succesfully", error);
        }
      );
    e.target.reset();
  };
  return (
    <Container maxWidth="lg">
      <Grid
        container
        direction="row"
        justify="space-between"
        className={classes.container}
      >
        <Grid item xs={12} md={4}>
          <Text className={classes.title} variant="h2" align="left">
            {"Get In Touch"}
          </Text>

          <Text className={classes.description} variant="body1" align="left">
            {`MzTech is a team of Software(mobile, web, desktop) development, Digital marketing, SEO, Google Analytics, learning management system, and usability experts who believe in the interconnectivity of digital efforts. We believe that all of these disciplines work together to execute successful products. We're ensuring that we bring expertise and personalized solutions to your specific setup.`}
          </Text>
        </Grid>

        <Grid item xs={12} md={7} className={classes.formContainer}>
          <form
            onSubmit={handleSubmit(getFormData)}
            className="contact-form"
            id="contact-form"
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  value={services}
                  onChange={(e) => setServices(e.target.value)}
                >
                  <MenuItem value="Design And Services">
                    Design And Services
                  </MenuItem>
                  <MenuItem value="Web Migration">Web Migration</MenuItem>
                  <MenuItem value="Search Engine Optimization">
                    Search Engine Optimization
                  </MenuItem>
                  <MenuItem value="Ecommerce">Ecommerce</MenuItem>
                  <MenuItem value="Learning Management System">
                    Learning Management System
                  </MenuItem>
                  <MenuItem value="App Development">App Development</MenuItem>
                  <MenuItem value="Cloud Server Setup">
                    Cloud Server Setup
                  </MenuItem>
                  <MenuItem value="Software Development">
                    Software Development
                  </MenuItem>
                  <MenuItem value="Digital Marketing">
                    Digital Marketing
                  </MenuItem>
                  <MenuItem value="Web Speed Optimization">
                    Web Speed Optimization
                  </MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Input
                  error={errors.email?.message}
                  label="Email *"
                  name="email"
                  {...register("email")}
                />
                <div className={classes.menualErrorFix}>
                  {errors.email?.message && <p>{errors.email?.message}</p>}
                </div>
              </Grid>
              <Grid item xs={12}>
                <Input
                  error={errors.message?.message}
                  label="How can we help you?*"
                  name="message"
                  multiline
                  rows={4}
                  rowsMax={15}
                  {...register("message")}
                />
                <div className={classes.menualErrorFix}>
                  {errors.message?.message && <p>{errors.message?.message}</p>}
                </div>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  backgroundColor="#02334d"
                  className={classes.submitBtn}
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};
export default GetInTouch;
