export const isLengthZero = (value) => {
  if (value.length === 0) {
    return true;
  }
  return false;
};
export const lengthChecker = (value, length, message, min) => {
  if (isLengthZero(value)) {
    return false;
  }

  if (min) {
    if (value.length < length) {
      return message;
    }
  }

  if (!min) {
    if (value.length > length) {
      return message;
    }
  }
  return false;
};

export const nameValidator = (value, message) => {
  if (isLengthZero(value)) {
    return false;
  }

  let result = /^[a-zA-Z\s]+$/.test(value);
  if (!result) {
    return message;
  }
  return false;
};

export const emailValidator = (value, message) => {
  if (isLengthZero(value)) {
    return false;
  }

  let result = /\S+@\S+\.\S+/.test(value);
  if (!result) {
    return message;
  }
  if (result) {
    return false;
  }
  return false;
};

export const phoneNumberValidator = (value, message) => {
  if (isLengthZero(value)) {
    return false;
  }
  let result = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s/0-9]*$/.test(value);
  if (!result) {
    return message;
  }
  if (value.length < 11) {
    return "The number should be at least 10 digits";
  }
  if (result) {
    return false;
  }

  return false;
};

export const messageValidator = (value, length, message) => {
  if (isLengthZero(value)) {
    return false;
  }
  if (value.length < length) {
    return message;
  }
  return false;
};

export const errorExist = (errors, value) => {
  if (
    !errors.firstName &&
    !errors.lastName &&
    !errors.email &&
    !errors.phone &&
    !errors.company &&
    !errors.message &&
    value.firstName.length &&
    value.lastName.length &&
    value.email.length &&
    value.phone.length &&
    value.company.length &&
    value.message.length
  ) {
    return false;
  } else {
    return true;
  }
};
