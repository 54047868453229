import React from "react";
import style from "./style";

const TeamNavbar = ({ navState, setNavState }) => {
  const classes = style();
  const { all, founders, marketing, uiux, webDevelopment, appDevelopment } =
    navState.value;
  const navChanger = (navItem) => {
    setNavState({
      ...navState,
      isClicked: {
        all: navState.value.all === navItem ? true : false,
        founders: navState.value.founders === navItem ? true : false,
        humanResource: navState.value.humanResource === navItem ? true : false,
        marketing: navState.value.marketing === navItem ? true : false,
        uiux: navState.value.uiux === navItem ? true : false,
        webDevelopment:
          navState.value.webDevelopment === navItem ? true : false,
        appDevelopment:
          navState.value.appDevelopment === navItem ? true : false,
      },
    });
  };
  return (
    <nav>
      <ul className={classes.navbar}>
        <li
          onClick={() => navChanger(all)}
          className={`${classes.navLink}  ${
            navState.isClicked.all && classes.isClicked
          }`}
        >
          {" "}
          All{" "}
        </li>
        <li
          onClick={() => navChanger(founders)}
          className={`${classes.navLink}  ${
            navState.isClicked.founders && classes.isClicked
          }`}
        >
          {" "}
          Founder{" "}
        </li>
        {/* <li
                    onClick={() => navChanger(humanResource)}
                    className={`${classes.navLink}  ${navState.isClicked.humanResource && classes.isClicked}`}
                    > Human Resource </li> */}
        <li
          onClick={() => navChanger(marketing)}
          className={`${classes.navLink}  ${
            navState.isClicked.marketing && classes.isClicked
          }`}
        >
          {" "}
          Marketing{" "}
        </li>
        <li
          onClick={() => navChanger(uiux)}
          className={`${classes.navLink}  ${
            navState.isClicked.uiux && classes.isClicked
          }`}
        >
          {" "}
          UI & UX{" "}
        </li>
        <li
          onClick={() => navChanger(webDevelopment)}
          className={`${classes.navLink}  ${
            navState.isClicked.webDevelopment && classes.isClicked
          }`}
        >
          {" "}
          Web Development{" "}
        </li>
        <li
          onClick={() => navChanger(appDevelopment)}
          className={`${classes.navLink}  ${
            navState.isClicked.appDevelopment && classes.isClicked
          }`}
        >
          {" "}
          App Development{" "}
        </li>
      </ul>
    </nav>
  );
};
export default TeamNavbar;
