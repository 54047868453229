import React, { Suspense, Fragment } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import ReactDOM from "react-dom";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";
import store from "./Redux/Store/store";
import { Provider } from "react-redux";

import App from "./App";
import theme from "./theme";
import GlobalStyles from './GlobalStyles';
import "./index.css";

import * as serviceWorker from "./serviceWorker";

const loader = document.querySelector(".loader");

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove("loader--hide");

const hideLoader = () => loader.classList.add("loader--hide");

// the setTimeout simulates the time it takes react to load, and is not part of the solution
setTimeout(
  () =>
    ReactDOM.render(
      <Provider store={store}>
        <Suspense fallback={<Fragment />}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyles />
            <ParallaxProvider>
              <App hideLoader={hideLoader} showLoader={showLoader} />
            </ParallaxProvider>
          </MuiThemeProvider>
        </Suspense>
      </Provider>,
      document.getElementById("root")
    ),
  1000
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
