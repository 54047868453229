import React,{useEffect, useState} from 'react';
import {Container,Grid,Box,Paper,Accordion,AccordionSummary,AccordionDetails,TextField} from '@material-ui/core';
import {Text,Button} from '../../Components';
import {useStyle} from './Utility';
import LinkIcon from '@material-ui/icons/Link';
import BusinessIcon from '@material-ui/icons/Business';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useParams} from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {getJob,jobSliceSelector} from "./../../Redux/Slices/jobSlice";
import smoothScrollTop from "../../Helpers/smoothScrollTop";
import {Link} from 'react-router-dom'


const JobDiscripation = () => {
    const {id} = useParams()
    const classes = useStyle ()
    const [activeJob,setActiveJob] = useState({})
    const [activeId, setActiveId] = useState(0)
    const [inputValue,setInputValue] = useState('')
    const [searchJob,setSearchJob] = useState([])
    const [value,setValue] = useState('');
    const dispatch = useDispatch();
    const { jobContent,loading } = useSelector(jobSliceSelector);

    const handleClick = id =>{
        const selectedJob = jobContent.find(item => item.jobId === id)
        setActiveJob(selectedJob)
        setActiveId(id)
    } 

    const handleChange = (e,newValue) =>{
        setValue(newValue)
        if (value) {
            const filterJob = jobContent.filter(job =>job.title.toLowerCase().trim().includes(value.toLowerCase().trim()))
            setSearchJob(filterJob)
        }
        if (value === '') {
            setSearchJob([])
        }
    }
    const fatch = async ()=>{

        const data = await dispatch(getJob())
        if ( id || activeId) {
            const selectedJob = data.payload.find(item => item.jobId === activeId)
            if (selectedJob) {
                setActiveJob(selectedJob)
                setActiveId(activeId)
            }else{
                setActiveJob(data.payload[0])
                setActiveId(data.payload[0]?.jobId)
            }
        }else{
            setActiveJob(data.payload[0])
            setActiveId(data.payload[0]?.jobId)
        }
        
        
    }
    
    useEffect(() => {
        
        if (!jobContent.length) {
            fatch()
        }else{
            if (id) {
                const selectedJob = jobContent.find(item => item.jobId === id)
                if (selectedJob) {
                    setActiveJob(selectedJob)
                    setActiveId(id)
                }else{
                    setActiveJob(jobContent[0])
                    setActiveId(jobContent[0]?.jobId)
                }
                
            }else{
                setActiveJob(jobContent[0])
                setActiveId(jobContent[0]?.jobId)
            }
        }
         smoothScrollTop()
        // eslint-disable-next-line
      }, []);

      
    
    useEffect(()=>{
        const findJob = jobContent.find(job =>job.title === value)
        if (findJob) {
            setActiveJob(findJob)
            setActiveId(findJob.jobId)
        }
         // eslint-disable-next-line
    },[value])

    if (loading) {
        return <Box my={10}>
          <Text variant='h3' align='center'>Loading...</Text>
        </Box>
      }
    
    return (
        <Box mb={3} mt={10}>
            <Container>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                <Box mb={2} className={classes.input}>
                <Autocomplete
                    freeSolo
                    onInputChange={(e, newInputValue) => handleChange(e,newInputValue)}
                    value={inputValue}
                    onChange={(e,newValue)=>{
                        setInputValue(newValue)
                    }}
                    options={searchJob.map((option) => option?.title)}
                    renderInput={(params) => (
                    <TextField {...params}
                    size='small' 
                    label="Find your next job..." 
                    margin="normal" 
                    variant="outlined"
                     />
                    )}
                />
                </Box>

                    {jobContent && jobContent?.map((item,index) =>{
                        return(
                            <Box key={index} mb={1}  onClick={()=>handleClick(item.jobId)} className={classes.paperShadow}>
                            <Paper  className={ `${item.jobId === activeId && classes.activeTab }`}>
                            <Box p={1}>
                            <Box my={1}>
                            <Text variant='h3' className={classes.fontBold} >
                                {item.title}
                            </Text>
                            </Box>
                            <Box>
                                <Text variant='body1' className={classes.fontGrey}>{item.JobType} | {item.deadline}</Text>
                            </Box>
                        </Box>
                    </Paper>
                            </Box>
                        )
                    })}
                </Grid>
                <Grid item xs={12} md={9} className={classes.paperShadowContainer}>
                <Paper >
                        <Box p={2}>
                        <Box display='flex' justifyContent='space-between' alignItems='center' my={1}>
                            <Text variant='h3' className={classes.fontBold} >
                                {activeJob?.title}
                            </Text>
                            <Box display='flex' alignItems='center'>
                                <Box mr={1} display='flex' alignItems='center'>
                                <LinkIcon />
                                </Box>
                                <Text variant='body2'>
                                    Share
                                </Text>
                            </Box>
                            </Box>
                            
                            <Box display='flex' alignItems='center'>
                            <Box display='flex' alignItems='center' mr={1}>
                                <Box mr={1} display='flex' alignItems='center'>
                                <BusinessIcon className={classes.fontGrey} />
                                </Box>
                                <Text variant='body1' className={classes.fontGrey}>
                                    {activeJob?.company}
                                </Text>
                            </Box>
                            <Box display='flex' alignItems='center'>
                                <Box mr={1} display='flex' alignItems='center'>
                                <LocationOnIcon className={classes.fontGrey} />
                                </Box>
                                <Text variant='body1' className={classes.fontGrey}>
                                    {activeJob?.location}
                                </Text>
                            </Box>
                            </Box>

                            <Box display='flex' justifyContent='space-between' my={1}>
                                <Box></Box>
                                <Link to={`/career/${activeJob.jobId}/apply`}>
                                <Button backgroundColor='#02334D' textColor='#fff' className={classes.btn}>Apply Now</Button>
                                </Link>
                            </Box>

                            <Box p={2}>
                                <Box my={1}>
                                <Text className={classes.fontBold}>
                                Minimum Qualifications:
                                </Text>
                                </Box>
                                <Box >
                                    <Text dangerouslySetInnerHTML={{ __html: activeJob && activeJob?.minimumQulifications }} />
                                </Box>
                            </Box>
                            <Box p={2}>
                                <Box my={1}>
                                <Text className={classes.fontBold}>
                                Preferred Qualifications:
                                </Text>
                                </Box>
                                <Box >
                                    <Text  dangerouslySetInnerHTML={{ __html: activeJob && activeJob?.preferredQualifications }} />
                                        
                                </Box>
                            </Box>

                            <Box mb={1} className={classes.paperShadow}>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                
                                >
                                <Text className={classes.fontBold}>About the job</Text>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Text dangerouslySetInnerHTML={{ __html: activeJob && activeJob?.aboutTheJob }} />

                                </AccordionDetails>
                            </Accordion>
                            </Box>
                            <Box mb={1} className={classes.paperShadow}>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                
                                >
                                <Text className={classes.fontBold}>Responsibilities</Text>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Text dangerouslySetInnerHTML={{ __html: activeJob && activeJob?.responsibilities }} />
                                    
                                </AccordionDetails>
                            </Accordion>
                            </Box>
                            <Box mb={1} className={classes.paperShadow}>
                            <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                
                                >
                                <Text className={classes.fontBold}>Facilities</Text>
                                </AccordionSummary>
                                <AccordionDetails>
                                <Text dangerouslySetInnerHTML={{ __html: activeJob && activeJob?.facilities }} />
                                
                                </AccordionDetails>
                            </Accordion>
                            </Box>
                            <Box display='flex' justifyContent='space-between' my={2}>
                                <Box></Box>
                                <Link to={`/career/${activeJob.jobId}/apply`}>
                                <Button backgroundColor='#02334D' textColor='#fff' className={classes.btn}>Apply Now</Button>
                                </Link>
                                
                            </Box>
                        </Box>
                    </Paper>

                </Grid>

            </Grid>
            
        </Container>
        </Box>
    )
}

export default JobDiscripation