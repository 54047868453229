import React from "react";
import ImageOverlay from "../../Components/ImageOverlay";
import { Box } from "@material-ui/core";
import { useStyle } from "./Utility";
import theme from "../../theme";
import { ThemeProvider } from "@material-ui/core/styles";


const Header = ({content, colors}) => {
  const style = useStyle();
  return (
    <ThemeProvider theme={theme}>
    <Box className={style.height}>
      <ImageOverlay
        image={content?.imageUrl}
        title={content?.title}
        subTitle={content?.subTitle}
        overlayFalse={true}
        buttonOneText='Contact Us'
        buttonOneLink='/contact'
        btnBackgroundColor={colors?.btnColor ? colors?.btnColor : colors?.backgroundColor}
        bgColor={theme.palette.common.transparent}

      />
    </Box>
    </ThemeProvider>
  );
};

export default Header;
