import React from "react";
import { CustomSlider, Text } from "../../Components";
import { Container, Box } from "@material-ui/core";
import { useStyle } from "./Utility";
import { ReactSVG } from "react-svg";

const TechnologyUsed = (props) => {
  const { content } = props;
  const classes = useStyle(props);
  const settings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box className={classes.background}>
      <Box pt={10}>
        <Text align="center" variant="h2">
          {content?.title}
        </Text>
      </Box>
      <Box className={classes.sliderContainer}>
        <Container maxWidth="md">
          <CustomSlider moreSetting={settings}>
            {content?.imageUrl?.map((item, index) => {
              if (item.toolName) {
                return (
                  <Box
                    key={index}
                    px={2}
                    className={classes.technologyContainer}
                  >
                    <ReactSVG src={item.imageUrl} className={classes.icon} />
                    <Text
                      variant="body1"
                      align="center"
                      className={classes.toolName}
                    >
                      {" "}
                      {item.toolName}{" "}
                    </Text>
                  </Box>
                );
              } else {
                return (
                  <Box
                    key={index}
                    px={1}
                    className={classes.technologyImageSize}
                  >
                    <img
                      src={item}
                      alt="Techonlogy images"
                      className={classes.technologyImageSize}
                    />
                  </Box>
                );
              }
            })}
          </CustomSlider>
        </Container>
      </Box>
    </Box>
  );
};

export default TechnologyUsed;
