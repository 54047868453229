import React, { useEffect, useState } from "react";
import { Fab } from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Toolbar from "@material-ui/core/Toolbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage/HomePage";
import AboutUs from "./Pages/aboutUs/AboutUs";
import TeamPage from "./Pages/TeamPage/TeamPage";
import ContactUsPage from "./Pages/ContactUsPage/ContactUsPage";
import ServicesPage from "./Pages/Services/ServicesPage";
import Header from "./Layouts/HomeLayout/Header";
import Footer from "./Layouts/HomeLayout/Footer";
import ServiceDetailPage from "./Pages/serviceDetailPage/ServiceDetailPage";
import Portfolio from "./Pages/portfolio/Portfolio";
import JobDescription from "./Pages/jobDescription/JobDescription";
import Career from "./Pages/Career/career";
import CareerPage from "./Pages/CareerPage/CareerPage";
import CreateJob from "./Pages/jobDescription/CreateJob";
import LegalPage from "./Pages/LegalPage/LegalPage";
import ScrollTop from "./ScrollTop";

const App = (props) => {
  const { hideLoader } = props;
  useEffect(hideLoader, [hideLoader]);

  const [scroll, setScroll] = useState(true);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scroll = window.scrollY < 56 ? true : false;

      setScroll(scroll);
    });
  }, []);

  return (
    <Router>
      <Header scroll={scroll} />

      <Toolbar id="back-to-top-anchor" />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/about" component={AboutUs} />
        <Route exact path="/contact" component={ContactUsPage} />
        <Route exact path="/career" component={CareerPage} />
        <Route exact path="/team" component={TeamPage} />
        <Route exact path="/services" component={ServicesPage} />
        <Route exact path="/services/:service" component={ServiceDetailPage} />
        <Route exact path="/portfolio" component={Portfolio} />
        <Route exact path="/career/:id/new" component={CreateJob} />
        <Route exact path="/career/:id/apply" component={Career} />
        <Route exact path="/career/:id" component={JobDescription} />
        <Route exact path="/legal" component={LegalPage} />
      </Switch>
      <Footer />
      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </Router>
  );
};
export default App;
