import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, makeStyles } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export const useStyle = makeStyles((theme) => ({
  arrowButton: {
    "& .slick-next:before": {
      display: "none",
    },
    "& .slick-prev:before": {
      display: "none",
    },
    "& .slick-next:hover": {
      opacity: 0.7,
    },
    "& .slick-prev:hover": {
      opacity: 0.7,
    },
  },
}));

const PortfolioSlider = ({
  moreSetting = {},
  children,
  bgColor = "#fff",
  color = "#02334D",
}) => {
  const classes = useStyle();
  const SmiplePrevArrow = ({ className, style, onClick }) => (
    <Box
      style={{
        ...style,
        width: 40,
        height: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        left: -10,
        borderRadius: 50,
        zIndex: 1,
        boxShadow: `0 0 3px`,
        transition: "linear .2s",
        backgroundColor: bgColor,
        color,
      }}
      onClick={onClick}
      className={className}
    >
      <ChevronLeftIcon />
    </Box>
  );

  const SmipleNextArrow = ({ className, style, onClick }) => (
    <Box
      style={{
        ...style,
        width: 40,
        height: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        right: -10,
        borderRadius: 50,
        boxShadow: `0 0 3px`,
        transition: "linear .2s",
        backgroundColor: bgColor,
        color,
      }}
      onClick={onClick}
      className={className}
    >
      <ChevronRightIcon />
    </Box>
  );

  const settings = {
    infinite: false,
    speed: 500,
    nextArrow: <SmipleNextArrow />,
    prevArrow: <SmiplePrevArrow />,
  };
  const finalSetting = {
    ...settings,
    ...moreSetting,
  };
  return (
    <Slider {...finalSetting} className={classes.arrowButton}>
      {children}
    </Slider>
  );
};

export default PortfolioSlider;
