import { Helmet } from "react-helmet";
import { Box, Container, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { Text } from "../../Components";
import CareerGrid from "./CareerGrid";
import { careerContents, useStyles } from "./Utility";
import { teamGroupJobPost } from "../jobDescription/Utility";
import smoothScrollTop from "../../Helpers/smoothScrollTop";
import JobTeamSlider from "./JobTeamSlider";
import OfferList from "../serviceDetailPage/OfferList";
import { useDispatch, useSelector } from "react-redux";
import { getJob, jobSliceSelector } from "./../../Redux/Slices/jobSlice";
import Header from "./Header";

const CareerPage = ({ width, services }) => {
  const [searchJob, setSearchJob] = useState("");
  const classes = useStyles();
  const dispatch = useDispatch();
  const { jobContent, loading } = useSelector(jobSliceSelector);

  const { benefits } = careerContents;

  useEffect(() => {
    if (!jobContent.length) {
      dispatch(getJob());
    }
    smoothScrollTop();
    // eslint-disable-next-line
  }, []);

  const formHandler = (e) => {
    setSearchJob(e.target.value);
  };

  const filterJob = (data) =>
    data.filter(
      (d) =>
        searchJob === "" ||
        d.title.toLowerCase().includes(searchJob.toLowerCase().trim())
    );
  if (loading) {
    return (
      <Box my={10}>
        <Text variant="h3" align="center">
          Loading...
        </Text>
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>MzTech - Career</title>
        <meta
          name="description"
          content="It's an exciting and important time to be a part of MzTech corp. We're strong, innovative, passtionate and we want you to be part our success stories."
        />
        <meta name="robots" content="nofollow" />
      </Helmet>
      <Grid container className={classes.careerPageWrapper}>
        <Header />
        <Container maxWidth="md">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            py={3}
          >
            <Text display="inline" variant="h2" className={classes.titleBold}>
              Recent{" "}
              <Text
                display="inline"
                variant="h2"
                className={classes.titleColor}
              >
                Job
              </Text>
            </Text>
            <span className={classes.seachInput}>
              <InputBase
                className={classes.input}
                placeholder="Enter the job title"
                inputProps={{ "aria-label": "enter the job title" }}
                value={searchJob}
                onChange={(e) => formHandler(e)}
                name="jobSearchInput"
              />
              <SearchIcon />
            </span>
          </Box>
          {jobContent && <CareerGrid posts={filterJob(jobContent)} />}
        </Container>
        <Grid xs={12} className={classes.teamSection}>
          <Box className={classes.teamContent}>
            <Text className={classes.teamSubTitle} variant="h3">
              Teams
            </Text>
            <Text variant="h2" className={classes.teamTitle}>
              Find your team
            </Text>
            <Text variant="body1">
              "Talent wins games, but teamwork and intelligence win
              championships." Together we are a family & build products that
              improve people's lives. Want to be a MzTecher? Find your desire
              role.
            </Text>
          </Box>
        </Grid>
        <Container maxWidth="md">
          {jobContent && <JobTeamSlider content={teamGroupJobPost} />}
        </Container>
        <Grid xs={12} className={classes.teamSection}>
          <Box className={classes.mzContent}>
            <Text className={classes.teamSubTitle} variant="h3">
              MzTech Corp.
            </Text>
            <Text variant="h2" className={classes.teamTitle}>
              Perks and Benefits of MzTech
            </Text>
            <Text variant="body1">
              When you join MzTech, you're joining a global tech hub. Each
              member is provided with incredible perks and benefits. We provide
              support and research to grow new ideas designed to tackle unique
              challenges.
            </Text>
          </Box>
        </Grid>
      </Grid>
      <OfferList content={benefits} />
    </>
  );
};

export default CareerPage;
