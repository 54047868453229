import {useEffect} from 'react'
import {Text} from './../../Components'
import Title from './Title'
import Description from './Description'
import FAQ from './FAQ'
import {Container, makeStyles} from '@material-ui/core';
import useFetch from './../../Helpers/customHooks/useFetch'
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    sectionContainer: {
        marginTop: theme.palette.spacing*10,
    },
    pageHeading: {
        fontWeight: theme.typography.fontWeightBold,
    },
    faq: {
        marginTop: theme.palette.spacing*3,
        marginBottom: theme.palette.spacing*10,
        width: '80%', 
        margin: 'auto'
    }
}));

const LegalPage = (props) => {

    const classes = useStyles(props);
    const pageContent = useFetch()
    const location = useLocation()
    console.log(location)
    useEffect(()=> {
        document.title = "MzTech - Legal";
        if (location.hash) {
            let element = document.getElementById(location.hash).offsetTop - 100
            if (element) {
                window.scroll({top: element, behavior: 'smooth'})
            }
        } else {
        window.scrollTo({top:0,left:0, behavior: "smooth"})
        }
}, [location])


    return (
        <Container  maxWidth="lg">
            {/* PrivacyPolicy */}
            <div id='#privacy-policy' className={classes.sectionContainer}>
                <Text
                    variant='h1'
                    align='center'
                    className={classes.pageHeading}
                >
                    {'Privacy Policy'}
                </Text>
                {pageContent?.Legal?.PrivacyPolicy && 
                    Object.entries(pageContent?.Legal?.PrivacyPolicy).map(([id, {title, description}]) => (
                        <>
                            <Title title={title} id={id}/>
                            <Description description={description} id={id} />
                        </>
                ))}    
            </div>

            {/* CookiePolicy */}
            <div id='#cookie-policy' className={classes.sectionContainer}>
                <Text
                    variant='h1'
                    align='center'
                    className={classes.pageHeading}
                >
                    {'Cookie Policy'}
                </Text>
                {pageContent?.Legal?.CookiePolicy && 
                    Object.entries(pageContent?.Legal?.CookiePolicy).map(([id, {title, description}]) => (
                        <>
                            <Title title={title} id={id}/>
                            <Description description={description} id={id} />
                        </>
                ))}    
            </div>

            {/* TermsAndConditions */}
            <div id='#terms-and-conditions' className={classes.sectionContainer}>
                <Text
                    variant='h1'
                    align='center'
                    className={classes.pageHeading}
                >
                    {'Terms & Conditions'}
                </Text>
                {pageContent?.Legal?.TermsAndConditions && 
                    Object.entries(pageContent?.Legal?.TermsAndConditions).map(([id, {title, description}]) => (
                        <>
                            <Title title={title} id={id}/>
                            <Description description={description} id={id} />
                        </>
                ))}    
            </div>


            {/* FAQ */}
            <div id='#faq' className={classes.sectionContainer}>
                <Text
                    variant='h1'
                    align='center'
                    className={classes.pageHeading}
                >
                    {'FAQs'}
                </Text>
                <div className={classes.faq}>
                    {pageContent?.Legal?.FAQ && 
                        Object.entries(pageContent?.Legal?.FAQ).map(([id, {question, answer}]) => (
                            <FAQ question={question} id={id} answer={answer} />
                    ))}   
                </div>
            </div>



        </Container>

    )
}
export default LegalPage