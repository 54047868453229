import { NavLink } from "react-router-dom";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  navSelected: {
    color: `${theme.palette.secondary.main} !important`,
  },
}));

const Links = ({ link, name }) => {
  const style = useStyle();
  return (
    <NavLink exact to={link} activeClassName={style.navSelected}>
      {name}
    </NavLink>
  );
};

export default Links;
