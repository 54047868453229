import React from "react";
import ImageOverlay from "../../Components/ImageOverlay";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../../theme";
import image from "../../images/img3.jpg";

const Header = () => {
  return (
    <ThemeProvider theme={theme}>
      <ImageOverlay
        image={image}
        title="Contact Us"
        subTitle="We support a IT service industries across North America. Our marketplace includes thousands of qualified service providers with a range of skillsets ready to help your get work done."
        bgColor={theme.palette.common.imageOverlayBlue}
      />
    </ThemeProvider>
  );
};

export default Header;
