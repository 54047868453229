export const whyMzTech = {
  sectionTitle: "Why Organizations Choose MzTech",
  headLine: "The Best Solution For Your Business",
  content: [
    {
      header: "Top tech talent",
      text: "Our team members are well-versed in their respective fields and have had many years of professional experience.",
    },
    {
      header: "Quality work",
      text: "The final product will have the look that you desire as well as obtain the results that you expect.",
    },
    {
      header: "Fair and competitive pricing",
      text: "Expenses are always approved by you and payments are fully protected every single time.",
    },
    {
      header: "24/7 customer support",
      text: "We are available to provide customer service at any time of the day - or night.",
    },
  ],
  videoUrl: "https://mztech29.wistia.com/medias/c2ufyrk4dv",
};
