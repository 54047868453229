import {Text} from '../../../Components/index'
import { ReactSVG } from "react-svg";
import useStyles from './style'

const FormHeader = (props) => {
    const {header,title} = props
    const classes = useStyles(props)
    return (
            <div
                className={classes.header}
            >
                <ReactSVG src={header?.imageUrl} />
                <Text
                    variant="h4"
                    className={classes.jobTitle}
                >
                    {title && title}
                </Text>   
            </div>
    )

}
export default FormHeader