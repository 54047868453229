import React from "react";
import Button from "@material-ui/core/Button";

function CustomButton({ children,borderColor,fontSize, textColor, backgroundColor, ...rest }) {
  return (
    <>
      <Button {...rest} size="large" style={{
          color:textColor,
          backgroundColor: backgroundColor ? backgroundColor : '#B3294E',
          border:`1px solid ${borderColor}`,
          fontSize,
      }}>{children}</Button>
    </>
  );
}

export default CustomButton;
