import axios from 'axios';

export const uploadImages = async (files, folder = 'products', uploadPreset = 'product_photos') => {
  const photos = [];
  try {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('folder', folder);
      formData.append('upload_preset', uploadPreset);
      const res = await axios.post('https://api.cloudinary.com/v1_1/dv244dkbr/image/upload', formData, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
      });
      photos.push({
        imageUrl: res.data.secure_url,
        publicKey: res.data.public_id,
      });
    }
    return photos;
  } catch (error) {
    console.log(error);
  }
};
