import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
    sectionTitle: { 
        color: `${theme.palette.common.primaryHover} !important`,
        textAlign: 'center',
        padding: theme.palette.spacing*3,
    },
    sectionAbout:{
        padding: theme.palette.spacing*3,
    },
    sectionAboutText: {
        paddingBottom: theme.palette.spacing*9,
    }
}))
export default useStyles