import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { firestore } from "../../Helpers/Firebase";

export const storingPageContentsToRedux = createAsyncThunk(
  "homePageContent/StoringPageContentsToRedux",
  async () => {
    try {
      const data = {};
      const contents = await firestore.collection("homePageContent").get();
      contents.docs.forEach((doc) => (data[doc.id] = doc.data()));
      return data;
    } catch (error) {
      throw Error(error);
    }
  }
);

export const homePageContentSlice = createSlice({
  name: "homePageContent",
  initialState: {
    loading: true,
    homePageContent: null,
  },
  reducers: {},

  extraReducers: {
    [storingPageContentsToRedux.fulfilled]: (state, action) => {
      state.loading = false;
      state.homePageContent = action.payload;
    },
  },
});

export const homePageContentSelector = (state) =>
  state.entities.homePageContent;

export default homePageContentSlice.reducer;
