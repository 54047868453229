import React from "react";
import { Box, Grid, Container } from "@material-ui/core";
import { Text, VideoPlayer } from "../../Components";
import thumbnail from "../../images/videoThumnail.png";
import { useStyle } from "./Utility";

const content = {
  title: "Client's Success story",
  textPartOne:
    "We are proud to be a customer-centric company. For your success in the digital transformation, we ensure the fastest delivery with 360 solutions & services.",
  textPartTwo:
    "Our team of accomplished engineers and tech professionals utilize cutting edge resources to implement our services that encompass a host of development procedures to meet your needs today - all in order to open doors for tomorrow in the wide world of technology.",
  videoUrl: "https://mztech29.wistia.com/medias/c2ufyrk4dv",
};

const ClientStory = () => {
  const style = useStyle();
  return (
    <Box py={4}>
      <Container>
        <Grid container justify="center" alignItems="center" spacing={2}>
          <Grid item xs={12} md={6}>
            <Box mb={2}>
              <Text variant="h2">{content.title}</Text>
            </Box>
            <Box>
              <Text variant="h4" className={style.summary}>
                {content.textPartOne}
              </Text>
            </Box>
            <Box>
              <Text variant="h4" className={style.summary}>
                {content.textPartTwo}
              </Text>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <VideoPlayer url={content.videoUrl} thumbnail={thumbnail} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ClientStory;
