import React from "react";
import { Box, Container, Grid } from "@material-ui/core";
import { Message, MessageDivider, Text } from "../../../Components";
import { content, useStyle } from "./Utility";

const PayoneersMessage = () => {
  const style = useStyle();
  return (
    <Box>
      <Box
        py={3}
        display="flex"
        justifyContent="center"
        className={style.titleBackground}
      >
        <Box display="inline" mr="7px">
          <Text display="inline" align="center" variant="h2">
            Message from
          </Text>
        </Box>
        <Text
          display="inline"
          align="center"
          variant="h2"
          className={style.titleColor}
        >
          Pioneers
        </Text>
      </Box>
      <Box className={style.container} py={4}>
        <Container>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            spacing={2}
            wrap="wrap"
          >
            <Grid item xs={12} md={5}>
              <Box
                display="flex"
                justifyContent="flex-end"
                className={style.messageBox}
              >
                <Message content={content[0]} direction="row" />
              </Box>
            </Grid>
            <Grid item xs={12} md={1}>
              <Box display="flex" justifyContent="center">
                <MessageDivider />
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box
                display="flex"
                justifyContent="flex-start"
                className={style.messageBox}
              >
                <Message content={content[1]} direction="row-reverse" />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default PayoneersMessage;
