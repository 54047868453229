import { makeStyles } from "@material-ui/core";

export const useStyle = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.common.white,
  },
  body: {
    // backgroundColor: theme.palette.common.greyishBackground,
  },
  imageContainer: {
    width: "100%",
    height: 300,
    objectFit: "contain",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  customerImage: {
    width: 100,
    height: 100,
    objectFit: "cover",
    borderRadius: "50%",
  },
  titleBold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  titleColor: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.secondary.main,
  },

  viewTab: {
    color: theme.palette.common.primaryHover,
    textTransform: "capitalize !important",
  },
  gridImageContainer: {
    maxWidth: 300,
    height: 250,
    objectFit: "cover",
    overflow: "hidden",
    borderRadius: 10,
    border: "0.1px solid whitesmoke",
    [theme.breakpoints.down("sm")]: {
      width: 350,
      height: "auto",
    },
  },
  projectLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "capitalize !important",
    cursor: "pointer",
    color: `${theme.palette.common.secondaryHover} !important`,
    "&:hover": { fontWeight: theme.typography.fontWeightMedium },
    "& svg": { margin: 5, fontSize: 17 },
  },
}));

export const portfolio = [
  {
    imageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1621247233/MzTechCorp/portfolio%20pic/mobile_gdbdal.png",
    link: "/app-development",
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1621247233/MzTechCorp/portfolio%20pic/screen_m06ezi.png",
    link: "#",
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1621248805/MzTechCorp/portfolio%20pic/screen_vgnfnf.png",
    link: "/ecommerce",
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1621248805/MzTechCorp/portfolio%20pic/screen_vgnfnf.png",
    link: "/ecommerce",
  },
  {
    imageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1621248805/MzTechCorp/portfolio%20pic/screen_vgnfnf.png",
    link: "/ecommerce",
  },
];

export const portfolioGallery = [
  {
    squareImageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1622270195/MzTechCorp/portfolio%20pic/square-photo-agency_qom2it.webp",
    link: "https://kind-jennings-45e4fb.netlify.app",
    title: "Photo Agency",
  },
  {
    squareImageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1622271127/MzTechCorp/portfolio%20pic/sqaure-mz-training_sqhrsw.png",
    link: "https://mztraining.org/",
    title: "LMS MzTraining",
  },
  {
    squareImageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1622271127/MzTechCorp/portfolio%20pic/square-momedia_texhye.png",
    link: "https://momedia.netlify.app/",
    title: "MoMedia Entertainment",
  },
  {
    squareImageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1622270195/MzTechCorp/portfolio%20pic/square-mzecom_ksnyjp.webp",
    link: "https://ecom-mzdev.netlify.app/",
    title: "E-commerce",
  },
  {
    squareImageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1622270195/MzTechCorp/portfolio%20pic/square-clothing-ecom_rzv8jk.webp",
    link: "https://sad-archimedes-08ce3a.netlify.app",
    title: "Silion E-commerce",
  },
  {
    squareImageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1622270195/MzTechCorp/portfolio%20pic/square-mztube-app_cbleue.webp",
    link: "https://mztech.us/services/app-development",
    title: "LMS MzTube App",
  },
  {
    squareImageUrl:
      "https://res.cloudinary.com/dv244dkbr/image/upload/v1622710730/MzTechCorp/portfolio%20pic/image_enbj1a.png",
    link: "https://designaesthetics-50e3b.web.app/",
    title: "Design Aesthetic",
  },
];
