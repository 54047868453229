import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  "@global": {
    /**
     * Disable the focus outline, which is default on some browsers like
     * chrome when focusing elements
     */
    html: {
      scrollBehavior: "smooth",
      fontFamily: "Roboto, sans-serif",
      overflowX: "hidden",
    },
    "*:focus": {
      outline: 0,
    },

    "@keyframes left-right": {
      "0%": { left: 0, transform: "translateX(0)" },
      "100%": { left: "100%", transform: "translatex(-100px)" },
    },

    "@keyframes float": {
      "0%": { transform: "translateY(0px)" },
      "50%": { transform: "translatey(-20px);" },
      "100%": { transform: "translateY(0px)" },
    },

    "h1,h2,h3,h4,h5,h6,p": {
      // fontFamily: "Lato, sans-serif !important",
    },

    h1: {
      [theme.breakpoints.up("md")]: {
        fontSize: `${theme.fonts.font40} !important`,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: `${theme.fonts.font35} !important`,
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: `${theme.fonts.font20} !important`,
      },
    },
    h2: {
      [theme.breakpoints.up("md")]: {
        fontSize: `${theme.fonts.font35} !important`,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: `${theme.fonts.font25} !important`,
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: `${theme.fonts.font18} !important`,
      },
    },
    h3: {
      [theme.breakpoints.up("md")]: {
        fontSize: `${theme.fonts.font20} !important`,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: `${theme.fonts.font20} !important`,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: `${theme.fonts.font16} !important`,
      },
    },
    h4: {
      [theme.breakpoints.up("md")]: {
        fontSize: `${theme.fonts.font16} !important`,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: `${theme.fonts.font16} !important`,
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: `${theme.fonts.font14} !important`,
      },
    },
    p: {
      [theme.breakpoints.up("md")]: {
        fontSize: `${theme.fonts.font14} !important`,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: `${theme.fonts.font14} !important`,
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: `${theme.fonts.font13} !important`,
      },
    },
    ".buttonFontsize": {
      [theme.breakpoints.up("md")]: {
        fontSize: `${theme.fonts.font16} !important`,
      },
      [theme.breakpoints.down("md")]: {
        fontSize: `${theme.fonts.font14} !important`,
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: `${theme.fonts.font14} !important`,
      },
    },

    ".container": {
      width: "100%",
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      marginRight: "auto",
      marginLeft: "auto",
      [theme.breakpoints.up("sm")]: {
        maxWidth: 540,
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: 720,
      },
      [theme.breakpoints.up("lg")]: {
        maxWidth: 1170,
      },
    },
    ".row": {
      display: "flex",
      flexWrap: "wrap",
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    ".container-fluid": {
      width: "100%",
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      marginRight: "auto",
      marginLeft: "auto",
      maxWidth: 1370,
    },

    ".no-decoration": {
      textDecoration: "none",
    },

    ".capitalize": {
      textTransform: "capitalize !important",
    },

    ".MuiInput-input": {
      paddingLeft: "10px !important",
    },
  },
});

function globalStyles() {
  return null;
}

export default withStyles(styles, { withTheme: true })(globalStyles);
