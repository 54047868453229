import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  logosContainer: {
    backgroundColor: theme.palette.common.white,
    maxHeight: 140,
    margin: 0,
  },
  sliderGradian: {
    "&:before": {
      content: "''",
      position: "absolute",
      left: 0,
      top: 0,
      width: "10%",
      height: 140,
      background: "linear-gradient(to right, white, rgba(255, 255, 255, 0))",
      zIndex: 3,
    },

    "&:after": {
      content: "''",
      position: "absolute",
      right: 0,
      top: 0,
      width: "10%",
      height: 140,
      background: "linear-gradient(to left, white, rgba(255, 255, 255, 0))",
      zIndex: 3,
    },
  },
  logo: { "& svg ": { height: "100%", width: "80%", maxHeight: 110 } },
}));

export default useStyle;
